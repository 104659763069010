import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {

                // Languages

                'lang_none': 'Choose language',
                'lang_en': 'English',
                'lang_bg': 'Bulgarian',
                'lang_nl': 'Dutch',
                'lang_ru': 'Russian',
                'lang_hy': 'Armenian',
                'lang_fr': 'French',
                'lang_de': 'German',
                'lang_es': 'Spanish',
                'lang_it': 'Italian',
                'lang_el': 'Greek',
                'lang_pt': 'Portuguese',
                'lang_hr': 'Croatian',

                // Messages

                'messages_topics_no_qna_registered': 'No Q&A registered, end of user interaction',
                'messages_topics_no_qna_visible': 'Q&A hidden due to filtering',

                // General Labels

                'label_search_button_search': 'Search',
                'label_search_button_clear': 'Clear',
                'label_autocomplete_select': 'Select',

                'label_image_dnd_drop_file': 'DROP IMAGE FILE',
                'label_image_dnd_or': 'OR',
                'label_image_dnd_browse': 'BROWSE',

                'label_video_dnd_drop_file': 'DROP VIDEO FILE',
                'label_video_dnd_or': 'OR',
                'label_video_dnd_browse': 'BROWSE',
                'label_video_dnd_uploading': 'Uploading video...',
                'label_video_dnd_may_take_a_while': 'This may take a while',
                'label_video_dnd_maximum_size': 'Maximum size:',

                'label_yes': 'Yes',
                'label_no': 'No',

                // Data Labels

                'label_sentiment_group_0_20': '0-2',
                'label_sentiment_group_21_40': '2.1-4',
                'label_sentiment_group_41_60': '4.1-6',
                'label_sentiment_group_61_80': '6.1-8',
                'label_sentiment_group_81_100': '8.1-10',

                'label_gender_male': 'Male',
                'label_gender_female': 'Female',
                'label_gender_other': 'Other',

                'label_age_group_0_17': '0-17',
                'label_age_group_18_25': '18-25',
                'label_age_group_26_35': '26-35',
                'label_age_group_36_55': '36-55',
                'label_age_group_56': '56+',
                'label_age_group_undefined': 'undefined',

                // Charts

                'vega_chart_no_data_title': 'No data to display.',
                'vega_chart_no_data_subtitle': 'Please select a different time range.',
                'scalable_time_chart_stacked_by': 'stacked by',
                'scalable_time_chart_scaled_by': 'scaled by',
                'scalable_time_chart_showing_as': 'showing as',
                'scalable_time_chart_label_mean': 'mean',
                'scalable_time_chart_label_stack': 'stack',

                'chart_filter_time_seconds': 'Seconds',
                'chart_filter_time_minutes': 'Minutes',
                'chart_filter_time_hours': 'Hours',
                'chart_filter_time_days': 'Days',
                'chart_filter_time_months': 'Months',

                'chart_filter_stacking_sentiment': 'Sentiment',
                'chart_filter_stacking_gender': 'Gender',
                'chart_filter_stacking_age_group': 'Age Groups',
                'chart_filter_stacking_none': 'None',

                'chart_filter_legend_vote_rates': 'vote rates',
                'chart_filter_legend_score_group': 'score group',

                'cookie_message_body': 'Our dashboard uses a persistent first-party cookie to keep you logged in. By using our service and proceeding to log in you agree to our use of the cookie technology.',
                'cookie_message_dismiss': 'Ok, Got it',

                // Login page

                'login_page_title': 'Sign in to kazva.bg dashboard',
                'login_page_title_forgotten': 'Forgot password',
                'login_page_subtitle': 'Sign in with your email and password.',
                'login_page_subtitle_forgotten': 'Enter your email below, and we will send you a reset password link via email.',
                'login_form_label_username': 'Username / Email',
                'login_form_label_password': 'Password',
                'login_form_button_login': 'Login',
                'login_form_button_forgotten_password': 'Forgot password?',
                'forgotten_password_form_label_email': 'Email',
                'forgotten_password_form_button_submit': 'Submit',
                'forgotten_password_form_button_back_to_login': 'Back to login',
                'forgotten_password_form_message_success': 'An email with instructions to reset your password is sent to',
                'forgotten_password_form_label_current': 'Current password',
                'forgotten_password_form_label_new': 'New password',
                'forgotten_password_form_label_confirm': 'Confirm password',
                'forgotten_password_form_button_confirm': 'Confirm',

                // Register page



                // Navigation

                'nav_header_topics_overview': 'Topics Overview',
                'nav_header_topics_management': 'topics management',
                'nav_header_organizations_list': 'Organizations List',
                'nav_header_users_list': 'Users List',
                'nav_header_admin': 'ADMIN',
                'nav_header_profile_hello': 'Hello',
                'nav_header_profile_edit': 'edit my profile',
                'nav_header_profile_change_password': 'change password',
                'nav_header_profile_logout': 'logout',
                'nav_header_organization_your': 'Your organization',
                'nav_header_organization_my_account': 'My organization & account',
                'nav_header_organization_alerts': 'Configure alerts',
                'nav_header_organization_manage': 'Manage organizations',
                'nav_header_organization_create': 'Create organization',
                'nav_header_organization_dont_have_account': 'Don\'t have an account?',
                'nav_header_organization_sign_up': 'Sign up',
                'nav_header_organization_already_have_account': 'Already have an account?',
                'nav_header_organization_sign_in': 'Sign in',
                'nav_header_live_feed': 'Live Feed',

                'footer_privacy_policy': 'Privacy Policy',
                'footer_cookie_policy': 'Cookie Policy',
                'footer_data_privacy': 'Data & Privacy Rights',
                'footer_app_version': 'app version',
                'floating_button_contact_support': 'contact support',

                // My Organization and Account

                'my_org_title': 'My organization & account',
                'my_org_card_title': 'my organization details & settings',
                'my_org_plan_title': 'my account plan',
                'my_org_plan_subscription_type': 'subscription type',
                'my_org_plan_change_or_upgrade': 'change or upgrade',
                'my_org_plan_status': 'status',
                'my_org_plan_cancel_subscription': 'cancel subscription',
                'my_org_plan_upgrade_to_get_more': 'Upgrade to get more',
                'my_org_details_account_plan': 'Account plan',
                'my_org_details_topics_limit': 'Topics limit to',
                'my_org_details_upgrade_to_get_more': 'upgrade account to get more',
                'my_org_details_default_language': 'Default language',

                // Edit My Profile Modal

                'edit_profile_title': 'edit my profile',
                'edit_profile_field_avatar': 'user avatar',
                'edit_profile_field_first_name': 'first name',
                'edit_profile_field_last_name': 'last name',
                'edit_profile_field_email': 'email address',
                'edit_profile_field_email_sublabel_1': 'email address is used for sign in',
                'edit_profile_field_email_sublabel_2': '. user will receive a link to set their personal password',
                'edit_profile_field_phone': 'phone number',
                'edit_profile_field_timezone': 'timezone',
                'edit_profile_field_user_locale': 'user locale',
                'edit_profile_field_user_language': 'language',
                'edit_profile_field_organization': 'company/organization name *',
                'edit_profile_title_change_password': 'change password',
                'edit_profile_button_change_your_password': 'change your password',

                // Modals

                'modal_general_close': 'Close',
                'modal_general_ok': 'Ok',
                'modal_general_save': 'Save',
                'modal_general_cancel': 'Cancel',

                'modal_topic_delete_title': 'Confirm Delete',
                'modal_topic_delete_message': 'Are you sure you want to delete topic "{{ topicName }}"?',

                'modal_org_delete_title': 'Confirm Delete Organization',
                'modal_org_delete_message': 'Are you sure you want to delete organization "{{ orgName }}"?',

                'modal_alert_delete_title': 'Confirm Delete',
                'modal_alert_delete_message': 'Are you sure you want to delete alert {{ alertIndex }}?',

                'modal_qna_delete_title': 'Confirm Delete',
                'modal_qna_delete_message': 'Are you sure you want to delete question "{{ question }}"?',

                'modal_qna_answer_delete_title': 'Confirm Delete',
                'modal_qna_answer_delete_message': 'Are you sure you want to delete answer "{{ answerText }}"?',

                'modal_greetings_delete_title': 'Confirm Delete',
                'modal_greetings_delete_message': `Are you sure you want to delete "{{ greetingText }}"?`,

                'modal_translations_delete_title': "Delete translation?",
                'modal_translations_delete_message': `Are you sure you want to delete {{ languageIcon }}{{ languageName }} translation?`,

                'modal_link_config_delete_title': 'Confirm Delete',
                'modal_link_config_delete_message': `Are you sure you want to delete configuration for voting link: "{{ voterPreviewUrl }}"?`,

                'modal_link_config_reset_title': 'Confirm Reset',
                'modal_link_config_reset_message': 'Are you sure you want to reset to default link image and description?',

                // Topic management page

                'topic_management_title': 'topics management',
                'topic_management_header_id': 'ID',
                'topic_management_header_name': 'Topic Name',
                'topic_management_header_modified': 'Modified on',
                'topic_management_header_analytics': 'Analytics',
                'topic_management_header_actions': 'Actions',
                'topic_management_button_create_new': 'create a new topic',
                'topic_management_row_graph': 'Graph Data',
                'topic_management_row_links': 'Links to topic',
                'topic_management_row_use_as_template': 'use as template',
                'topic_management_row_edit': 'edit',
                'topic_management_row_delete': 'delete',

                // Topic Media Embed

                'topic_embed_view_topic': 'view topic',
                'topic_embed_view_topic_mobile': 'view topic on mobile, by scanning the qr code',
                'topic_embed_link_source': 'Link Source',
                'topic_embed_link_no_sources': 'no sources',
                'topic_embed_image_size': 'Image Size',
                'topic_embed_download': 'download',
                'topic_embed_or_open_in_browser': 'or open topic in url in your browser:',
                'topic_embed_copy_url': 'copy url to clipboard',
                'topic_embed_embeddable_snippet': 'embeddable html snippet for selected sources',
                'topic_embed_embeddable_preview': 'embeddable snippet preview',
                'topic_embed_embeddable_html_code': 'html code snippet',
                'topic_embed_copy_snippet': 'copy to clipboard',

                // Organization Management Page

                'org_management_title': 'Manage Organizations',
                'org_management_header_id': 'ID',
                'org_management_header_name': 'Organization Name',
                'org_management_header_created_on': 'Created On',
                'org_management_header_created_by': 'Created By',
                'org_management_header_tier': 'Tier',
                'org_management_header_subscription': 'Subscription',
                'org_management_header_active': 'Active',
                'org_management_header_vote_alerts_1': 'Vote',
                'org_management_header_vote_alerts_2': 'alerts',
                'org_management_header_actions': 'Actions',
                'org_management_button_create_new': 'Create a new organization',

                'org_management_row_active_m': 'active',
                'org_management_row_inactive_m': 'inactive',
                'org_management_row_expiring_m': 'expiring',
                'org_management_row_expired_m': 'expired',
                'org_management_row_active_f': 'active',
                'org_management_row_inactive_f': 'inactive',
                'org_management_row_active_pl': 'active',
                'org_management_row_inactive_pl': 'inactive',
                'org_management_row_switch': 'switch',
                'org_management_row_details': 'organization details',
                'org_management_row_delete': 'delete',

                // Topics tree

                'topics_tree_search_topics': 'search topics',
                'topics_tree_direct_votes': 'DIRECT VOTES',
                'topics_tree_related_votes': 'RELATED VOTES',
                'topics_tree_trend': 'trend',

                // Topic Overview Page

                'topic_overview_apply_period': 'apply period',
                'topic_overview_create_new': 'create a new topic',
                'topic_overview_mobile_topics': 'Topics',
                'topic_overview_mobile_statistics': 'Statistics',

                'lifetime_stats_first_vote': 'first vote on',
                'lifetime_stats_all_votes': 'all votes',
                'lifetime_stats_all_answers': 'all answers',
                'lifetime_stats_last_vote_on': 'last vote on',

                'filter_bar_ratings_for': 'topics ratings for',
                'filter_bar_sorted_by': 'sorted by',

                'filter_date_last_hour': 'last 1 hour',
                'filter_date_today': 'today',
                'filter_date_last_7_days': 'last 7 days',
                'filter_date_last_30_days': 'last 30 days',
                'filter_date_select_range': 'select time range',
                'filter_date_selected_period': 'selected period',

                'filter_sort_options_most': 'most votes',
                'filter_sort_options_least': 'least votes',
                'filter_sort_options_highest': 'highest rating',
                'filter_sort_options_lowest': 'lowest rating',

                'macro_stats_total_votes': 'Total votes for {{ timeIntervalLabel }}',
                'macro_stats_total_answers': 'Total answers given for {{ timeIntervalLabel }}',
                'macro_stats_most_voted_topic': 'Most voted topic for {{ timeIntervalLabel }}',
                'macro_stats_most_answered_topic': 'Most answered topic for {{ timeIntervalLabel }}',
                'macro_stats_highest_rated_topic': 'Highest rated topic for {{ timeIntervalLabel }}',
                'macro_stats_lowest_rated_topic': 'Lowest rated topic for {{ timeIntervalLabel }}',
                'macro_stats_no_data': 'No data',

                'macro_stats_overall_rating_for': 'Overall rating for',
                'macro_stats_votes_trend': 'votes trend',
                'macro_stats_last_voted': 'last voted',
                'macro_stats_anonymous_voters_for': 'anonymous voters for',
                'macro_stats_anonymous_votes_for': 'anonymous votes for',
                'macro_stats_returning_people_for': 'returning people for',
                'macro_stats_votes_from_returning': 'votes from returning people for',
                'macro_stats_increased_opinion': 'increased opinion from returning people for',
                'macro_stats_decreased_opinion': 'decreased opinion from returning people for',

                'macro_stats_of': 'of',
                'macro_stats_anonymous_people': 'Anonymous People',
                'macro_stats_all_people': 'All People',
                'macro_stats_anonymous_votes': 'Anonymous Votes',
                'macro_stats_all_votes': 'All Votes',
                'macro_stats_repeating_people': 'Repeating People',
                'macro_stats_repeating_votes': 'Repeating Votes',
                'macro_stats_opinion_improved': 'Opinion Improved',
                'macro_stats_opinion_decreased': 'Opinion Decreased',

                // Topic Details Page

                'topic_details_export_pdf': 'Export current view as PDF',
                'topic_details_presentation_mode': 'presentation mode',
                'topic_details_links_to_topic': 'links to topic',
                'topic_details_edit_topic': 'edit topic',
                'topic_details_show_filters': 'show filters',
                'topic_details_filter_voters_data_set': 'filter voters data set',
                'topic_details_votes_from': 'Voters data from',
                'topic_details_mobile_votes_from': 'Votes from',

                'topic_filters_and_from_sources': 'and from sources',
                'topic_filters_autosuggest_maximum_added': 'maximum of {{ maxSelections }} selections added',
                'topic_filters_autosuggest_type_to_add': '+ type to add or select from the list',
                'topic_filters_autosuggest_hit': 'hit',
                'topic_filters_autosuggest_or': 'or',
                'topic_filters_autosuggest_to_add_tag': 'to add tag',
                'topic_filters_facebook': 'facebook',
                'topic_filters_email': 'email',
                'topic_filters_anonymous': 'anonymous',
                'topic_filters_showing_only_latest': 'only latest vote per voter',
                'topic_filters_showing_all': 'all votes per voter',
                'topic_filters_count_only_latest': 'count only latest vote per voter',
                'topic_filters_anonymous_voters': 'anonymous voters',
                'topic_filters_facebook_voters': 'facebook voters',
                'topic_filters_email_voters': 'email voters',
                'topic_filters_button_apply': 'Apply Filter',
                'topic_filters_button_reset': 'Reset',

                // Topic Analytics Charts

                'topic_analytics_tab_votes': 'Votes Analysis',
                'topic_analytics_tab_questionnaire': 'Questionnaire Analysis',
                'topic_analytics_tab_feed': 'Votes Feed',

                'topic_analytics_chart_votes_by_time_title': 'Votes by time',
                'topic_analytics_chart_votes_by_time_yaxis': 'Votes mean',
                'topic_analytics_chart_votes_by_time_xaxis': 'Days',

                'topic_analytics_chart_votes_by_volume_title': 'Votes by volume',
                'topic_analytics_chart_votes_by_volume_yaxis': 'Total votes',
                'topic_analytics_chart_votes_by_volume_xaxis': 'Days',

                'topic_analytics_chart_votes_by_gender_title': 'Votes by gender',
                'topic_analytics_chart_votes_by_gender_yaxis': 'Gender',
                'topic_analytics_chart_votes_by_gender_xaxis': 'Votes Count',

                'topic_analytics_chart_votes_by_age_title': 'Votes by age',
                'topic_analytics_chart_votes_by_age_yaxis': 'Age group',
                'topic_analytics_chart_votes_by_age_xaxis': 'Votes Count',

                'topic_analytics_chart_votes_by_location_title': 'Votes by location',
                'topic_analytics_chart_votes_by_location_yaxis': 'Locations',
                'topic_analytics_chart_votes_by_location_xaxis': 'Votes Count',
                'topic_analytics_chart_votes_by_location_show_more': 'show more',
                'topic_analytics_chart_votes_by_location_show_less': 'show less locations',
                'topic_analytics_chart_votes_by_location_show_votes_map': 'show votes map',
                'topic_analytics_chart_votes_by_location_hide_votes_map': 'hide votes map',

                'topic_analytics_chart_votes_map_title': 'Votes map',

                'topic_analytics_chart_votes_by_source_title': 'Votes by source',
                'topic_analytics_chart_votes_by_source_yaxis': 'Sources',
                'topic_analytics_chart_votes_by_source_xaxis': 'Votes Count',

                'topic_analytics_chart_questions_title': 'Questions by answer volume',
                'topic_analytics_chart_questions_yaxis': 'Questions',
                'topic_analytics_chart_questions_xaxis': 'Total answers',

                'topic_analytics_question_list_title': 'Questions list',

                // Topic Questions Tab

                'topic_question_list_ordered_by': 'Questions ordered by',
                'topic_question_list_showed_in': 'showed in',
                'topic_question_list_one_column': 'one column',
                'topic_question_list_two_column': 'two columns',
                'topic_question_list_collapse_all': 'collapse all',
                'topic_question_list_expand_all': 'expand all',

                'topic_question_order_placeholder': 'question order',
                'topic_question_order_highest_average': 'highest average rating',
                'topic_question_order_lowest_average': 'lowest average rating',
                'topic_question_order_most_answers': 'most answers given',
                'topic_question_order_least_answers': 'least answers given',

                'topic_question_card_percentage': '% of all votes',
                'topic_question_card_votes_collected': 'votes collected, with average answering time',
                'topic_question_card_expand_graph_details': 'expand graph details',
                'topic_question_card_collapse_graph_details': 'collapse graph details',
                'topic_question_card_chart_answers': 'Answers',
                'topic_question_card_chart_gender': 'Gender',
                'topic_question_card_chart_xaxis': 'Total answers',

                // Topic Vote Feed

                'topic_vote_feed_title': 'Latest votes feed showing as',
                'topic_vote_feed_tab_chronological': 'Chronological View',
                'topic_vote_feed_tab_group_by_question': 'Group By Question',
                'topic_vote_feed_tab_group_by_voter': 'Group By Voter',
                'topic_vote_feed_showing_feed_data': 'Showing feed data for',
                'topic_vote_feed_showing_options': 'options',
                'topic_vote_feed_showing_options_all': 'all',
                'topic_vote_feed_showing_options_filtered': 'filtered',
                'topic_vote_feed_showing_options_sentiment': 'sentiment',
                'topic_vote_feed_showing_options_gender': 'gender',
                'topic_vote_feed_showing_options_and': 'and',
                'topic_vote_feed_showing_options_age_groups': 'age groups',
                'topic_vote_feed_showing_options_on': 'On',
                'topic_vote_feed_showing_options_off': 'Off',

                'topic_vote_feed_filter_by_sentiment': 'By sentiment',
                'topic_vote_feed_filter_by_gender': 'By gender',
                'topic_vote_feed_filter_by_age_group': 'By age group',
                'topic_vote_feed_toggle_show': 'show',
                'topic_vote_feed_filter_reset': 'reset',
                'topic_vote_feed_filter_apply': 'Apply filters',

                'topic_vote_feed_search_placeholder': 'search in questions, answers and sources',
                'topic_vote_feed_meta_info_expand_all': 'expand all',
                'topic_vote_feed_meta_info_collapse_all': 'collapse all',
                'topic_vote_feed_meta_info_loaded': 'loaded',
                'topic_vote_feed_meta_info_loaded_all': 'loaded all',
                'topic_vote_feed_meta_info_out_of': 'out of',
                'topic_vote_feed_meta_info_total': 'total',
                'topic_vote_feed_export_as_csv': 'Export filtered feed as csv',
                'topic_vote_feed_export_popover': 'Export the answers feed in chronological order in a comma-separated-value file (.csv) with a comma symbol as a delimiter.',
                'topic_vote_feed_deprecated_questions_message_1': 'The answers feed contains votes data related to deleted questions or modified questions titles. Those questions, marked with',
                'topic_vote_feed_deprecated_questions_message_2': 'are not included in the Questionnaire analysis.',
                'topic_vote_feed_show_edited_or_removed': 'show answers of edited or removed questions',

                'topic_vote_timeline_by': 'by',
                'topic_vote_timeline_by_unknown': 'by unknown',
                'topic_vote_timeline_on': 'on',
                'topic_vote_timeline_from': 'from',
                'topic_vote_timeline_sources': 'sources',
                'topic_vote_timeline_gave': 'gave',
                'topic_vote_timeline_vote': 'vote',
                'topic_vote_timeline_votes': 'votes',
                'topic_vote_timeline_gives': 'gives',
                'topic_vote_timeline_opinion_no_change': 'no change',
                'topic_vote_timeline_opinion_increase': 'increase in opinion',
                'topic_vote_timeline_opinion_decrease': 'decrease in opinion',
                'topic_vote_timeline_with_overall_rating': 'with overall rating',
                'topic_vote_timeline_answered_with': 'answered with',
                'topic_vote_timeline_image_preview': 'image preview',
                'topic_vote_timeline_answer_preview': 'answer preview',
                'topic_vote_timeline_sec': 'sec',
                'topic_vote_timeline_votes_with_answers': 'votes with answers. average answering time',
                'topic_vote_timeline_votes_no_answers': 'no answers given for the selected time range',
                'topic_vote_timeline_show_next_answers': 'show next {{ pageSize }} answers',
                'topic_vote_timeline_answered': 'answered',
                'topic_vote_timeline_questions': 'questions',
                'topic_vote_timeline_took': 'took',
                'topic_vote_timeline_answering_time': 'with average answering time',
                'topic_vote_timeline_load_next_voters': 'load next voters',

                // Topic Edit Page

                'topic_details_including': 'including',
                'topic_builder_title': 'editing "{{ topicName }}" topic',
                'topic_builder_step_basic_title': 'Basic Topic Details',
                'topic_builder_step_basic_description': 'Set topic name & default language',
                'topic_builder_step_config_title': 'Topic Configuration',
                'topic_builder_step_config_description': 'PARENT TOPIC, NEXT TOPIC, ALERTS',
                'topic_builder_step_qna_title': 'Questions & Answers',
                'topic_builder_step_qna_description': 'SUPPLY AND CONFIGURATE Q&A',
                'topic_builder_step_after_vote_title': 'After Vote Configuration',
                'topic_builder_step_after_vote_description': 'VOTE RESULTS CONFIGURATION',
                'topic_builder_step_greeting_title': 'Greeting Messages',
                'topic_builder_step_greeting_description': 'SET TOPIC INITIAL GREETING MESSAGE SETTINGS',
                'topic_builder_step_languages_title': 'Languages',
                'topic_builder_step_languages_description': 'ADD ALTERNATIVE LANGUAGES AND TRANSLATE TOPIC TEXTS',
                'topic_builder_step_distribution_title': 'Topic Distribution Links',
                'topic_builder_step_distribution_description': 'CONFIGURE SHARING LINK AND VOTING LINKS WITH SOURCES',

                'topic_builder_button_back': 'Back',
                'topic_builder_button_next': 'Next',

                'topic_builder_progress_title': 'progress',
                'topic_builder_progress_cancel': 'Cancel',
                'topic_builder_progress_update': 'Update',
                'topic_builder_progress_publish': 'Publish',
                'topic_builder_progress_published': 'published',
                'topic_builder_progress_last_edited': 'last edited',
                'topic_builder_progress_by': 'by',
                'topic_builder_progress_changes_saved': 'ALL CHANGES SAVED!',
                'topic_builder_progress_links_to_topic': 'Links to topic',
                'topic_builder_progress_up_to_date': 'TOPIC IS UP TO DATE',

                'topic_builder_private_topic_label': 'private topic',
                'topic_builder_private_topic_sublabel': 'private topics do not show in the kazva.bg search results and cannot be shared',
                'topic_builder_disable_topic_label': 'disable topic',
                'topic_builder_disable_topic_sublabel': 'disabled topics cannot be voted on',
                'topic_builder_default_language_label': 'default language',
                'topic_builder_default_language_sublabel': 'The Default Language cannot be changed once the topic is published.',
                'topic_builder_topic_name_label': 'topic name',
                'topic_builder_topic_name_sublabel': 'topic alias name, used as identifier',
                'topic_builder_topic_name_placeholder': 'My New Topic Name',
                'topic_builder_topic_name_warning': 'The Topic Title cannot be changed once the topic is published.',
                'topic_builder_url_name_label': 'topic url name',
                'topic_builder_url_name_sublabel': 'topic url preview',
                'topic_builder_url_name_placeholder': 'kazva.bg/',
                'topic_builder_display_name_label': 'topic display name',
                'topic_builder_display_name_sublabel': 'how do you feel about',
                'topic_builder_display_name_warning': 'Recommended length of topic display name is max 80 characters',
                'topic_builder_dash_name_label': 'topic dashboard name',
                'topic_builder_topic_description_label': 'topic description',
                'topic_builder_external_description_label': 'External description url',
                'topic_builder_external_description_sublabel': 'Add an external website as additional information if needed',
                'topic_builder_should_give_consent_label': 'Voter should give consent for contacting them',

                'topic_builder_parent_topic_title': 'parent topic',
                'topic_builder_parent_topic_root': 'this is the root topic',
                'topic_builder_parent_topic_selected': 'Selected Parent Topic',
                'topic_builder_parent_topic_placeholder': 'Select a topic',
                'topic_builder_next_topics_title': 'Next topics',
                'topic_builder_next_topics_label': 'Select next topic',
                'topic_builder_next_topics_placeholder': 'Select a topic',
                'topic_builder_next_topics_not_exist': 'Selected next topic does not exist. Please, select a topic from the list.',
                'topic_builder_channels_title': 'Topic Channels',
                'topic_builder_channels_label': 'Select channels',
                'topic_builder_channels_private_warning': 'the topic is set to "private", so it will not be listed in any channel',

                'topic_builder_alerts_title': 'alert configuration',
                'topic_builder_alerts_unavailable': 'Unavailable for your plan',
                'topic_builder_alerts_turned_off': 'Vote alerts are not turned on for your organization. You will not receive emails after votes.',
                'topic_builder_alerts_invalid': 'There are invalid alerts. Please remove or fix them first.',
                'topic_builder_alerts_maximum_reached': 'Maximum number of alerts reached for this topic.',
                'topic_builder_alerts_create_new': 'create new alert',
                'topic_builder_alerts_delete': 'delete alert',
                'topic_builder_alerts_send_email': 'send email to all recipients if vote is in range',
                'topic_builder_alerts_emails_label': 'alert emails',
                'topic_builder_alerts_emails_sublabel': 'recipients',
                'topic_builder_alerts_interval_sublabel': 'time interval (in minutes)',
                'topic_builder_alerts_interval_hourly': 'hourly',
                'topic_builder_alerts_interval_daily': 'daily',
                'topic_builder_alerts_interval_weekly': 'weekly',
                'topic_builder_alerts_interval_n_mins': 'every {{ interval }} min',
                'topic_builder_alerts_interval_or_custom': 'or custom:',
                'topic_builder_alerts_alert': 'alert',

                'topic_builder_qna_expand_all': 'expand all',
                'topic_builder_qna_collapse_all': 'collapse all',
                'topic_builder_qna_new': 'add a new question',
                'topic_builder_qna_answer_new': 'add an answer',
                'topic_builder_qna_question': 'Question',
                'topic_builder_qna_question_delete': 'delete question',
                'topic_builder_qna_question_duplicate': 'duplicate question',
                'topic_builder_qna_question_duplicate_below': 'duplicate below',
                'topic_builder_qna_question_duplicate_at_end': 'duplicate at the end',
                'topic_builder_qna_can_choose_multiple': 'voters can choose multiple answers',
                'topic_builder_qna_show_for_range': 'show if vote is in range',
                'topic_builder_qna_vote_range_inclusive': '* selected vote range values are inclusive',
                'topic_builder_qna_jump_only': 'jump only',
                'topic_builder_qna_jump_only_info': '* will only be displayed if jumped to from another question',
                'topic_builder_qna_open_ended': 'open-end answers',
                'topic_builder_qna_multiple_choice': 'multiple choice',
                'topic_builder_qna_dropdown': 'dropdown menu',
                'topic_builder_qna_data_warning': 'You will lose current data. Are you sure you want to continue?',
                'topic_builder_qna_answer_is_link': 'Answer is link',
                'topic_builder_qna_jump_to_question': 'jump to question',
                'topic_builder_qna_openended_label': 'placeholder text',
                'topic_builder_qna_openended_sublabel': 'optional placeholder text inside the answer text field',
                'topic_builder_qna_openended_allow_upload': 'Allow image upload',
                'topic_builder_qna_dropdown_answer_label': 'Available values',
                'topic_builder_qna_dropdown_answer_sublabel': 'The values which will be available in the dropdown menu, separated by a semicolon (;)',

                'topic_builder_after_vote_show': 'show',
                'topic_builder_after_vote_most_people': 'most people agree/disagree with you',
                'topic_builder_after_vote_most_men_women': 'most men/women agree/disagree with you',
                'topic_builder_after_vote_most_people_your_age': 'most people your age agree/disagree with you',
                'topic_builder_after_vote_most_men_women_your_age': 'most men/women your age agree/disagree with you',
                'topic_builder_after_vote_most_people_in_your_city': 'most people in your city agree/disagree with you',
                'topic_builder_after_vote_vote_again': 'vote on this again',
                'topic_builder_after_vote_copy_voting_link': 'copy voting link',
                'topic_builder_after_vote_share_on_facebook': 'share on facebook',
                'topic_builder_after_vote_copy_voting_link_sublabel': 'copy voting link is not available for private topics',
                'topic_builder_after_vote_share_on_facebook_sublabel': 'share on facebook is not available for private topics',
                
                'topic_builder_greetings_add_new': 'add a message',
                'topic_builder_greetings_time_to_show': 'time to show greeting',
                'topic_builder_greetings_seconds': 'seconds',

                'topic_builder_languages_default_label': 'default topic language',
                'topic_builder_languages_add_alternative': 'add alternative language',
                'topic_builder_languages_enfoce_language_label': 'enforce language',
                'topic_builder_languages_enfoce_language_sublabel': 'enforcing language will display the topic in this language, regardless of the voter\'s language preference',
                'topic_builder_languages_details_title': 'Basic details translations',
                'topic_builder_languages_details_title_label': 'Title',
                'topic_builder_languages_details_description_label': 'Description',
                'topic_builder_languages_qna_title': 'Questions & Answers Translations',
                'topic_builder_languages_qna_answers_title': 'Answers',
                'topic_builder_languages_greetings_title': 'Greetings translations',

                'topic_builder_link_config_description': 'Social media sharing link will be styled like shown below.',
                'topic_builder_link_config_description_1': 'You can ',
                'topic_builder_link_config_description_2': 'change',
                'topic_builder_link_config_description_3': 'the social media description text and image.',
                'topic_builder_link_config_reset_to_default': 'Reset to default',
                'topic_builder_link_config_customize': 'Customize',
                'topic_builder_link_config_sources_title': 'Voting link sources',
                'topic_builder_link_config_sources_subtitle': 'Add voting links with sources for votes tracking. Each voting link can include up to 3 source names. Each source name can contain up to 20 alpha-numeric characters.',
                'topic_builder_link_config_add_link_sources': 'add a link with sources',
                'topic_builder_link_config_link_preview': 'voting link preview',
                'topic_builder_link_config_upload_custom': 'Upload a custom image and input custom description text',
                'topic_builder_link_config_link_image_label': 'link image',
                'topic_builder_link_config_link_image_sublabel': 'a custom image used in sharing link with maximum resolution of 1200px by 630px. can be a gif with maximum size of 300kb',
                'topic_builder_link_config_link_description_label': 'link description text',
                'topic_builder_link_config_link_description_sublabel': 'a maximum of 300 characters descriptive text following your link. use this text to provide description in desired language',
                'topic_builder_link_config_link_preview_link': 'Preview link',
            }
        },
        bg: {
            translation: {

                // Languages

                'lang_none': 'Изберете език',
                'lang_en': 'Английски',
                'lang_bg': 'Български',
                'lang_nl': 'Нидерландски',
                'lang_ru': 'Руски',
                'lang_hy': 'Арменски',
                'lang_fr': 'Френски',
                'lang_de': 'Немски',
                'lang_es': 'Испански',
                'lang_it': 'Италиански',
                'lang_el': 'Гръцки',
                'lang_pt': 'Португалски',
                'lang_hr': 'Хърватски',

                // Messages

                'messages_topics_no_qna_registered': 'Няма В&О, край на потребителска интеракция',
                'messages_topics_no_qna_visible': 'В&О скрити заради филтриране',

                // General Labels

                'label_search_button_search': 'Търси',
                'label_search_button_clear': 'Изчисти',
                'label_autocomplete_select': 'Избери',

                'label_image_dnd_drop_file': 'ПУСНЕТЕ ФАЙЛ',
                'label_image_dnd_or': 'ИЛИ',
                'label_image_dnd_browse': 'ИЗБЕРЕТЕ',

                'label_video_dnd_drop_file': 'ПУСНЕТЕ ВИДЕО',
                'label_video_dnd_or': 'ИЛИ',
                'label_video_dnd_browse': 'ИЗБЕРЕТЕ',
                'label_video_dnd_uploading': 'Качване на видео...',
                'label_video_dnd_may_take_a_while': 'Това може да отнеме време',
                'label_video_dnd_maximum_size': 'Максимален размер:',

                'label_yes': 'Да',
                'label_no': 'Не',

                // Data Labels

                'label_sentiment_group_0_20': '0-2',
                'label_sentiment_group_21_40': '2.1-4',
                'label_sentiment_group_41_60': '4.1-6',
                'label_sentiment_group_61_80': '6.1-8',
                'label_sentiment_group_81_100': '8.1-10',

                'label_gender_male': 'Мъже',
                'label_gender_female': 'Жени',
                'label_gender_other': 'Други',

                'label_age_group_0_17': '0-17',
                'label_age_group_18_25': '18-25',
                'label_age_group_26_35': '26-35',
                'label_age_group_36_55': '36-55',
                'label_age_group_56': '56+',
                'label_age_group_undefined': 'непосочен',

                // Charts

                'vega_chart_no_data_title': 'Няма данни за показване.',
                'vega_chart_no_data_subtitle': 'Моля изберете различен времеви период.',
                'scalable_time_chart_stacked_by': 'насложени по',
                'scalable_time_chart_scaled_by': 'мащабирани по',
                'scalable_time_chart_showing_as': 'показване като',
                'scalable_time_chart_label_mean': 'средни',
                'scalable_time_chart_label_stack': 'насложени',

                'chart_filter_time_seconds': 'Секунди',
                'chart_filter_time_minutes': 'Минути',
                'chart_filter_time_hours': 'Часове',
                'chart_filter_time_days': 'Дни',
                'chart_filter_time_months': 'Месеци',

                'chart_filter_stacking_sentiment': 'Отношение',
                'chart_filter_stacking_gender': 'Пол',
                'chart_filter_stacking_age_group': 'Възрастова група',
                'chart_filter_stacking_none': 'Няма',

                'chart_filter_legend_vote_rates': 'оценки',
                'chart_filter_legend_score_group': 'групи оценки',

                'cookie_message_body': 'Нашият дашборд използва постоянна бисквитка от първа страна, за да ви държи вписани. Използвайки нашата услуга, вие се съгласявате с използването на бисквитки.',
                'cookie_message_dismiss': 'Ок, разбрах',

                // Login page

                'login_page_title': 'Вход в дашборда на kazva.bg',
                'login_page_title_forgotten': 'Забравена парола',
                'login_page_subtitle': 'Вход с електронна поща и парола.',
                'login_page_subtitle_forgotten': 'Въведете Вашата ел. поща отдолу и ще ви изпратим линк за възстановяване на паролата.',
                'login_form_label_username': 'Потребителско име / Ел. поща',
                'login_form_label_password': 'Парола',
                'login_form_button_login': 'Вход',
                'login_form_button_forgotten_password': 'Забравена парола?',
                'forgotten_password_form_label_email': 'Ел. поща',
                'forgotten_password_form_button_submit': 'Изпрати',
                'forgotten_password_form_button_back_to_login': 'Обратно към вход',
                'forgotten_password_form_message_success': 'Съобщение с инструкции за възстановяване на паролата е изпратено на',
                'forgotten_password_form_label_current': 'Текуща парола',
                'forgotten_password_form_label_new': 'Нова парола',
                'forgotten_password_form_label_confirm': 'Потвърдете новата парола',
                'forgotten_password_form_button_confirm': 'Потвърди',

                // Navigation

                'nav_header_topics_overview': 'Преглед на темите',
                'nav_header_topics_management': 'Управление на темите',
                'nav_header_organizations_list': 'Списък с организации',
                'nav_header_users_list': 'Списък с потребители',
                'nav_header_admin': 'Администрация',
                'nav_header_profile_hello': 'Здравейте',
                'nav_header_profile_edit': 'редактирай профил',
                'nav_header_profile_change_password': 'промени парола',
                'nav_header_profile_logout': 'изход',
                'nav_header_organization_your': 'Вашата организация',
                'nav_header_organization_my_account': 'Моята организация и акаунт',
                'nav_header_organization_alerts': 'Настройване на известия',
                'nav_header_organization_manage': 'Управление на организации',
                'nav_header_organization_create': 'Създай организация',
                'nav_header_organization_dont_have_account': 'Нямаш акаунт?',
                'nav_header_organization_sign_up': 'Регистрация',
                'nav_header_organization_already_have_account': 'Вече имаш акаунт?',
                'nav_header_organization_sign_in': 'Вход',
                'nav_header_live_feed': 'На Живо',

                'footer_privacy_policy': 'Политика за поверителност',
                'footer_cookie_policy': 'Политика за бисквитки',
                'footer_data_privacy': 'Права за данни и поверителност',
                'footer_app_version': 'версия',
                'floating_button_contact_support': 'свържи се с поддръжка',

                // My Organization and Account

                'my_org_title': 'Моята организация и акаунт',
                'my_org_card_title': 'Детайли и настройки на организация',
                'my_org_plan_title': 'Моят план',
                'my_org_plan_subscription_type': 'Вид абонамент',
                'my_org_plan_change_or_upgrade': 'промени или повиши',
                'my_org_plan_status': 'статут',
                'my_org_plan_cancel_subscription': 'прекрати абонамент',
                'my_org_plan_upgrade_to_get_more': 'Повиши, за да получиш повече',
                'my_org_details_account_plan': 'Текущ план',
                'my_org_details_topics_limit': 'Лимит на темите',
                'my_org_details_upgrade_to_get_more': 'Повиши, за да получиш повече',
                'my_org_details_default_language': 'Език по подразбиране',

                // Edit My Profile Modal

                'edit_profile_title': 'редактирай моя профил',
                'edit_profile_field_avatar': 'снимка на потребител',
                'edit_profile_field_first_name': 'собствено име',
                'edit_profile_field_last_name': 'фамилно име',
                'edit_profile_field_email': 'ел. поща',
                'edit_profile_field_email_sublabel_1': 'ел. поща се използва за вписване',
                'edit_profile_field_email_sublabel_2': '. потребителят ще получи линк, за да зададе своята парола.',
                'edit_profile_field_phone': 'тел. номер',
                'edit_profile_field_timezone': 'часова зона',
                'edit_profile_field_user_locale': 'локация',
                'edit_profile_field_user_language': 'език',
                'edit_profile_field_organization': 'име на компания/организация *',
                'edit_profile_title_change_password': 'промени парола',
                'edit_profile_button_change_your_password': 'промени парола',
                'edit_profile_button_save': 'Запази',
                'edit_profile_button_cancel': 'Откажи',

                // Modals

                'modal_general_close': 'Затвори',
                'modal_general_ok': 'Ок',
                'modal_general_save': 'Запази',
                'modal_general_cancel': 'Откажи',

                'modal_topic_delete_title': 'Потвърдете изтриване',
                'modal_topic_delete_message': 'Сигурни ли сте, че искате да изтриете тема "{{ topicName }}"?',

                'modal_org_delete_title': 'Потвърдете изтриване на организация',
                'modal_org_delete_message': 'Сигурни ли сте, че искате да изтриете организацията "{{ orgName }}"?',

                'modal_alert_delete_title': 'Потвърдете изтриване',
                'modal_alert_delete_message': 'Сигурни ли сте, че искате да изтриете Известие №{{ alertIndex }}?',

                'modal_qna_delete_title': 'Потвърдете изтриване',
                'modal_qna_delete_message': 'Сигурни ли сте, че искате да изтриете въпроса "{{ question }}"?',

                'modal_qna_answer_delete_title': 'Потвърдете изтриване',
                'modal_qna_answer_delete_message': 'Сигурни ли сте, че искате да изтриете отговора "{{ answerText }}"?',

                'modal_greetings_delete_title': 'Потвърдете изтриване',
                'modal_greetings_delete_message': `Сигурни ли сте, че искате да изтриете "{{ greetingText }}"?`,

                'modal_link_config_delete_title': 'Потвърдете изтриване',
                'modal_link_config_delete_message': `Сигурни ли сте, че искате да изтриете конфигурацията за линк за гласуване "{{ voterPreviewUrl }}"?`,

                'modal_link_config_reset_title': 'Потвърдете нулиране',
                'modal_link_config_reset_message': 'Сигурни ли сте, че искате да върнете обратно оригиналното изображение и описание?',

                // Topic management page

                'topic_management_title': 'Управление на темите',
                'topic_management_header_id': 'ID',
                'topic_management_header_name': 'Име на тема',
                'topic_management_header_modified': 'Променена на',
                'topic_management_header_analytics': 'Анализ',
                'topic_management_header_actions': 'Действия',
                'topic_management_button_create_new': 'създай нова тема',
                'topic_management_row_graph': 'Графични данни',
                'topic_management_row_links': 'Линкове към тема',
                'topic_management_row_use_as_template': 'използвай шаблон',
                'topic_management_row_edit': 'редактирай',
                'topic_management_row_delete': 'изтрий',

                // Topic Media Embed

                'topic_embed_view_topic': 'вижте тема',
                'topic_embed_view_topic_mobile': 'вижте тема на мобилно устройство, като сканирате qr кода',
                'topic_embed_link_source': 'Източник',
                'topic_embed_link_no_sources': 'без източник',
                'topic_embed_image_size': 'Размер',
                'topic_embed_download': 'изтегли',
                'topic_embed_or_open_in_browser': 'или отворете тема чрез URL във Вашия браузър:',
                'topic_embed_copy_url': 'копирай URL',
                'topic_embed_embeddable_snippet': 'HTML код за вграждане за избраните източници',
                'topic_embed_embeddable_preview': 'визуализация на код за вграждане',
                'topic_embed_embeddable_html_code': 'HTML код',
                'topic_embed_copy_snippet': 'копирай код',

                // Organization Management Page

                'org_management_title': 'Управление на организации',
                'org_management_header_id': 'ID',
                'org_management_header_name': 'Име на организация',
                'org_management_header_created_on': 'Създадена на',
                'org_management_header_created_by': 'Създадена от',
                'org_management_header_tier': 'План',
                'org_management_header_subscription': 'Абонамент',
                'org_management_header_active': 'Активна',
                'org_management_header_vote_alerts_1': 'Известия',
                'org_management_header_vote_alerts_2': 'за гласове',
                'org_management_header_actions': 'Действия',
                'org_management_button_create_new': 'създай нова организация',

                'org_management_row_active_m': 'активен',
                'org_management_row_inactive_m': 'неактивен',
                'org_management_row_expiring_m': 'изтичащ',
                'org_management_row_expired_m': 'изтекъл',
                'org_management_row_active_f': 'активна',
                'org_management_row_inactive_f': 'неактивна',
                'org_management_row_active_pl': 'активни',
                'org_management_row_inactive_pl': 'неактивни',
                'org_management_row_switch': 'смени',
                'org_management_row_details': 'детайли на организация',
                'org_management_row_delete': 'изтрий',

                // Topics tree

                'topics_tree_search_topics': 'търсете теми',
                'topics_tree_direct_votes': 'ДИРЕКТНИ ОЦЕНКИ',
                'topics_tree_related_votes': 'СВЪРЗАНИ ОЦЕНКИ',
                'topics_tree_trend': 'тенденция',

                // Topic Overview Page

                'topic_overview_apply_period': 'приложи период',
                'topic_overview_create_new': 'създай нова тема',
                'topic_overview_mobile_topics': 'Теми',
                'topic_overview_mobile_statistics': 'Статистика',

                'lifetime_stats_first_vote': 'първа оценка',
                'lifetime_stats_all_votes': 'оценки',
                'lifetime_stats_all_answers': 'отговори',
                'lifetime_stats_last_vote_on': 'последна оценка',

                'filter_bar_ratings_for': 'оценки на теми за',
                'filter_bar_sorted_by': 'сортирани по',

                'filter_date_last_hour': 'последния час',
                'filter_date_today': 'днес',
                'filter_date_last_7_days': 'последните 7 дни',
                'filter_date_last_30_days': 'последните 30 дни',
                'filter_date_select_range': 'изберете период',
                'filter_date_selected_period': 'избрания период',

                'filter_sort_options_most': 'най-много оценки',
                'filter_sort_options_least': 'най-малко оценки',
                'filter_sort_options_highest': 'най-висока оценка',
                'filter_sort_options_lowest': 'най-ниска оценка',

                'macro_stats_total_votes': 'Общо оценки за {{ timeIntervalLabel }}',
                'macro_stats_total_answers': 'Общо отговори за {{ timeIntervalLabel }}',
                'macro_stats_most_voted_topic': 'Най-оценявана тема за {{ timeIntervalLabel }}',
                'macro_stats_most_answered_topic': 'Най-отговаряна тема за {{ timeIntervalLabel }}',
                'macro_stats_highest_rated_topic': 'Най-високо оценената тема за {{ timeIntervalLabel }}',
                'macro_stats_lowest_rated_topic': 'Най-ниско оценената тема за {{ timeIntervalLabel }}',
                'macro_stats_no_data': 'Няма данни',

                'macro_stats_overall_rating_for': 'Цялостна оценка за',
                'macro_stats_votes_trend': 'Тенденция на оценките',
                'macro_stats_last_voted': 'последна оценка',
                'macro_stats_anonymous_voters_for': 'анонимни потребители за',
                'macro_stats_anonymous_votes_for': 'анонимни оценки за',
                'macro_stats_returning_people_for': 'повторни потребители за',
                'macro_stats_votes_from_returning': 'оценки от повторни потребители за',
                'macro_stats_increased_opinion': 'повишено мнение от повторни потребители за',
                'macro_stats_decreased_opinion': 'понижено мнение от повторни потребители за',

                'macro_stats_of': 'от',
                'macro_stats_anonymous_people': 'Анонимни потребители',
                'macro_stats_all_people': 'Всички потребители',
                'macro_stats_anonymous_votes': 'Анонимни оценки',
                'macro_stats_all_votes': 'Всички оценки',
                'macro_stats_repeating_people': 'Повторни потребители',
                'macro_stats_repeating_votes': 'Повторни оценки',
                'macro_stats_opinion_improved': 'Повишено мнение',
                'macro_stats_opinion_decreased': 'Понижено мнение',

                // Topic Details Page

                'topic_details_votes_from': 'Данни за оценки от',
                'topic_details_mobile_votes_from': 'Оценки от',
                'topic_details_export_pdf': 'Извлечи изглед като PDF',
                'topic_details_presentation_mode': 'режим презентация',
                'topic_details_links_to_topic': 'линкове към тема',
                'topic_details_edit_topic': 'редактирай тема',
                'topic_details_show_filters': 'покажи филтри',
                'topic_details_filter_voters_data_set': 'филтриране на данни от оценки',

                'topic_filters_and_from_sources': 'и от източници',
                'topic_filters_autosuggest_maximum_added': 'максимум {{ maxSelections }} селекции добавени',
                'topic_filters_autosuggest_type_to_add': '+ пишете или избирете от списък',
                'topic_filters_autosuggest_hit': 'натиснете',
                'topic_filters_autosuggest_or': 'или',
                'topic_filters_autosuggest_to_add_tag': 'за да добавите таг',
                'topic_filters_facebook': 'фейсбук',
                'topic_filters_email': 'ел. поща',
                'topic_filters_anonymous': 'анонимни',
                'topic_filters_showing_only_latest': 'само последната оценка от потребител',
                'topic_filters_showing_all': 'всички оценки от потребител',
                'topic_filters_count_only_latest': 'брой само последната оценка от потребител',
                'topic_filters_anonymous_voters': 'анонимни потребители',
                'topic_filters_facebook_voters': 'потребители с фейсбук',
                'topic_filters_email_voters': 'потребители с ел. поща',
                'topic_filters_button_apply': 'Приложи Филтри',
                'topic_filters_button_reset': 'Изчисти филтри',

                // Topic Analytics Charts

                'topic_analytics_tab_votes': 'Анализ на оценки',
                'topic_analytics_tab_questionnaire': 'Анализ на въпросник',
                'topic_analytics_tab_feed': 'Поток от оценки',

                'topic_analytics_chart_votes_by_time_title': 'Оценки по време',
                'topic_analytics_chart_votes_by_time_yaxis': 'Средна оценка',
                'topic_analytics_chart_votes_by_time_xaxis': 'Дни',

                'topic_analytics_chart_votes_by_volume_title': 'Оценки по обем',
                'topic_analytics_chart_votes_by_volume_yaxis': 'Общо оценки',
                'topic_analytics_chart_votes_by_volume_xaxis': 'Дни',

                'topic_analytics_chart_votes_by_gender_title': 'Оценки по пол',
                'topic_analytics_chart_votes_by_gender_yaxis': 'Пол',
                'topic_analytics_chart_votes_by_gender_xaxis': 'Брой оценки',

                'topic_analytics_chart_votes_by_age_title': 'Оценки по възраст',
                'topic_analytics_chart_votes_by_age_yaxis': 'Възрастова група',
                'topic_analytics_chart_votes_by_age_xaxis': 'Брой оценки',

                'topic_analytics_chart_votes_by_location_title': 'Оценки по локация',
                'topic_analytics_chart_votes_by_location_yaxis': 'Локации',
                'topic_analytics_chart_votes_by_location_xaxis': 'Брой оценки',
                'topic_analytics_chart_votes_by_location_show_more': 'покажи още',
                'topic_analytics_chart_votes_by_location_show_less': 'покажи по-малко локации',
                'topic_analytics_chart_votes_by_location_show_votes_map': 'покажи карта',
                'topic_analytics_chart_votes_by_location_hide_votes_map': 'скрий карта',

                'topic_analytics_chart_votes_map_title': 'Карта на оценките',

                'topic_analytics_chart_votes_by_source_title': 'Оценки по източник',
                'topic_analytics_chart_votes_by_source_yaxis': 'Източници',
                'topic_analytics_chart_votes_by_source_xaxis': 'Брой оценки',

                'topic_analytics_chart_questions_title': 'Въпроси по обем на отговорите',
                'topic_analytics_chart_questions_yaxis': 'Въпроси',
                'topic_analytics_chart_questions_xaxis': 'Общо отговори',

                'topic_analytics_question_list_title': 'Списък с въпроси',

                // Topic Questions Tab

                'topic_question_list_ordered_by': 'Въпроси подредени по',
                'topic_question_list_showed_in': 'показани в',
                'topic_question_list_one_column': 'една колона',
                'topic_question_list_two_column': 'две колони',
                'topic_question_list_collapse_all': 'смали всички',
                'topic_question_list_expand_all': 'разшири всички',

                'topic_question_order_placeholder': 'ред на въпросите',
                'topic_question_order_highest_average': 'най-висока средна оценка',
                'topic_question_order_lowest_average': 'най-ниска средна оценка',
                'topic_question_order_most_answers': 'най-много отговори',
                'topic_question_order_least_answers': 'най-малко отговори',

                'topic_question_card_percentage': '% от всички оценки',
                'topic_question_card_votes_collected': 'отговора събрани, със средно време за отговор',
                'topic_question_card_expand_graph_details': 'виж графика',
                'topic_question_card_collapse_graph_details': 'скрий графика',
                'topic_question_card_chart_answers': 'Отговори',
                'topic_question_card_chart_gender': 'Пол',
                'topic_question_card_chart_xaxis': 'Общо отговори',

                // Topic Vote Feed

                'topic_vote_feed_title': 'Поток от последните оценки',
                'topic_vote_feed_tab_chronological': 'Хронологически изглед',
                'topic_vote_feed_tab_group_by_question': 'Групирай по въпрос',
                'topic_vote_feed_tab_group_by_voter': 'Групирай по потребител',
                'topic_vote_feed_showing_feed_data': 'Показване на поток от данни за опции',
                'topic_vote_feed_showing_options': '',
                'topic_vote_feed_showing_options_all': 'всички',
                'topic_vote_feed_showing_options_filtered': 'филтрирани',
                'topic_vote_feed_showing_options_sentiment': 'отношения',
                'topic_vote_feed_showing_options_gender': 'полове',
                'topic_vote_feed_showing_options_and': 'и',
                'topic_vote_feed_showing_options_age_groups': 'възрастови групи',
                'topic_vote_feed_showing_options_on': 'Вкл',
                'topic_vote_feed_showing_options_off': 'Изкл',

                'topic_vote_feed_filter_by_sentiment': 'Отношение',
                'topic_vote_feed_filter_by_gender': 'Пол',
                'topic_vote_feed_filter_by_age_group': 'Възраст',
                'topic_vote_feed_toggle_show': 'покажи',
                'topic_vote_feed_filter_reset': 'изчисти',
                'topic_vote_feed_filter_apply': 'Приложи филтри',

                'topic_vote_feed_search_placeholder': 'търси във въпроси, отговори и източници',
                'topic_vote_feed_meta_info_expand_all': 'разшири всички',
                'topic_vote_feed_meta_info_collapse_all': 'прибери всички',
                'topic_vote_feed_meta_info_loaded': 'заредени',
                'topic_vote_feed_meta_info_loaded_all': 'заредени всички',
                'topic_vote_feed_meta_info_out_of': 'от общо',
                'topic_vote_feed_meta_info_total': '',
                'topic_vote_feed_export_as_csv': 'Извлечи филтриран поток като CSV',
                'topic_vote_feed_export_popover': 'Извлечи потока от отговори в хронологичен ред във файл от стойности, разделени със запетаи (.csv).',
                'topic_vote_feed_deprecated_questions_message_1': 'Потокът от отговори съдържа данни, свързани с изтрити въпроси или модифицирани заглавия на въпроси. Въпросите отбелязани с',
                'topic_vote_feed_deprecated_questions_message_2': 'не са включени в анализа на въпросника.',
                'topic_vote_feed_show_edited_or_removed': 'покажи отговори на редактирани или премахнати въпроси',

                'topic_vote_timeline_by': 'от',
                'topic_vote_timeline_by_unknown': 'от непознат',
                'topic_vote_timeline_on': 'на',
                'topic_vote_timeline_from': 'от',
                'topic_vote_timeline_sources': 'източници',
                'topic_vote_timeline_gave': 'даде',
                'topic_vote_timeline_vote': 'оценка',
                'topic_vote_timeline_votes': 'оценки',
                'topic_vote_timeline_gives': 'дава',
                'topic_vote_timeline_opinion_no_change': 'няма промяна',
                'topic_vote_timeline_opinion_increase': 'повишено мнение',
                'topic_vote_timeline_opinion_decrease': 'понижено мнение',
                'topic_vote_timeline_with_overall_rating': 'с обща оценка',
                'topic_vote_timeline_answered_with': 'отговорил',
                'topic_vote_timeline_image_preview': 'виж изображение',
                'topic_vote_timeline_answer_preview': 'виж отговор',
                'topic_vote_timeline_sec': 'сек',
                'topic_vote_timeline_votes_with_answers': 'оценки с отговори. Средно време за отговор',
                'topic_vote_timeline_votes_no_answers': 'няма отговори за избрания период',
                'topic_vote_timeline_show_next_answers': 'покажи следващите {{ pageSize }} отговора',
                'topic_vote_timeline_answered': 'отговори',
                'topic_vote_timeline_questions': 'въпроса',
                'topic_vote_timeline_took': 'отне',
                'topic_vote_timeline_answering_time': 'със средно време за отговор',
                'topic_vote_timeline_load_next_voters': 'зареди следващите оценки',

                // Topic Edit Page

                'topic_details_including': 'включително',
                'topic_builder_title': 'Редакция на тема "{{ topicName }}"',
                'topic_builder_step_basic_title': 'Основни Детайли',
                'topic_builder_step_basic_description': 'Задайте име и основен език на темата',
                'topic_builder_step_config_title': 'Настройка на темата',
                'topic_builder_step_config_description': 'НАДСТОЯЩА ТЕМА, СЛЕДВАЩА ТЕМА, ИЗВЕСТИЯ',
                'topic_builder_step_qna_title': 'Въпроси и Отговори',
                'topic_builder_step_qna_description': 'ЗАДАЙТЕ И НАСТРОЙТЕ В&О',
                'topic_builder_step_after_vote_title': 'След оценка',
                'topic_builder_step_after_vote_description': 'НАСТРОЙКА НА РЕЗУЛТАТИТЕ',
                'topic_builder_step_greeting_title': 'Начални съобщения',
                'topic_builder_step_greeting_description': 'ЗАДАЙТЕ НАЧАЛНИ ПОЗДРАВИТЕЛНИ СЪОБЩЕНИЯ',
                'topic_builder_step_languages_title': 'Езици',
                'topic_builder_step_languages_description': 'ДОБАВЕТЕ АЛТЕРНАТИВНИ ЕЗИЦИ',
                'topic_builder_step_distribution_title': 'Линкове за разпространение',
                'topic_builder_step_distribution_description': 'НАСТРОЙТЕ ЛИНКОВЕ ЗА СПОДЕЛЯНЕ',

                'topic_builder_button_back': 'Назад',
                'topic_builder_button_next': 'Напред',

                'topic_builder_progress_title': 'напредък',
                'topic_builder_progress_cancel': 'Откажи',
                'topic_builder_progress_update': 'Обнови',
                'topic_builder_progress_publish': 'Публикувай',
                'topic_builder_progress_published': 'публикувана',
                'topic_builder_progress_last_edited': 'последно обновена',
                'topic_builder_progress_by': 'от',
                'topic_builder_progress_changes_saved': 'ВСИЧКИ ПРОМЕНИ ЗАПАЗЕНИ!',
                'topic_builder_progress_links_to_topic': 'Линкове към тема',
                'topic_builder_progress_up_to_date': 'ТЕМАТА Е АКТУАЛНА',

                'topic_builder_private_topic_label': 'скрита тема',
                'topic_builder_private_topic_sublabel': 'скритите теми не излизат в резултатите на kazva.bg и не могат да се споделят',
                'topic_builder_disable_topic_label': 'деактивирана тема',
                'topic_builder_disable_topic_sublabel': 'на деактивираните теми не може да се гласува',
                'topic_builder_default_language_label': 'основен език',
                'topic_builder_default_language_sublabel': 'Основният език не може да бъде сменян, след като темата бъде публикувана.',
                'topic_builder_topic_name_label': 'име на тема',
                'topic_builder_topic_name_sublabel': 'името се използва и като идентификатор',
                'topic_builder_topic_name_placeholder': 'Моята нова тема',
                'topic_builder_topic_name_warning': 'Името на темата не може да се променя, след като темата бъде публикувана.',
                'topic_builder_url_name_label': 'URL име на тема',
                'topic_builder_url_name_sublabel': 'Излиза в линка към темата',
                'topic_builder_url_name_placeholder': 'kazva.bg/',
                'topic_builder_display_name_label': 'Екранно име на темата',
                'topic_builder_display_name_sublabel': 'какво ви е мнението за',
                'topic_builder_display_name_warning': 'Препоръчителната дължина на екранното име е максимум 80 символа',
                'topic_builder_dash_name_label': 'дашборд име на темата',
                'topic_builder_topic_description_label': 'Описание на темата',
                'topic_builder_external_description_label': 'Линк към външно описание',
                'topic_builder_external_description_sublabel': 'При необходимост добавете външен сайт за допълнителна информация',
                'topic_builder_should_give_consent_label': 'Потребителят трябва да даде съгласие, за да се свържете с него',

                'topic_builder_parent_topic_title': 'надстоящта тема',
                'topic_builder_parent_topic_root': 'Това е основната тема',
                'topic_builder_parent_topic_selected': 'Избрана надстояща тема',
                'topic_builder_parent_topic_placeholder': 'Изберете тема',
                'topic_builder_next_topics_title': 'Следващи теми',
                'topic_builder_next_topics_label': 'Изберете следваща тема',
                'topic_builder_next_topics_placeholder': 'Изберете тема',
                'topic_builder_next_topics_not_exist': 'Избраната тема не съществува. Моля изберете тема от списъка.',
                'topic_builder_channels_title': 'Канали на тема',
                'topic_builder_channels_label': 'Изберете канали',
                'topic_builder_channels_private_warning': 'темата е „скрита“, и затова няма да излиза в никой канал',

                'topic_builder_alerts_title': 'настройка на известия',
                'topic_builder_alerts_unavailable': 'Не е достъпно за Вашия план',
                'topic_builder_alerts_turned_off': 'Известията са изключени за Вашата организация. Няма да получавате съобщения при оценки.',
                'topic_builder_alerts_invalid': 'Има невалидни известия. Моля първо ги премахнете или оправете.',
                'topic_builder_alerts_maximum_reached': 'Максимален брой известия достигнат за тази тема.',
                'topic_builder_alerts_create_new': 'създай ново известие',
                'topic_builder_alerts_delete': 'изтрий известие',
                'topic_builder_alerts_send_email': 'изпрати съобщение на всички получатели ако оценката е в диапазона',
                'topic_builder_alerts_emails_label': 'ел. пощи за известия',
                'topic_builder_alerts_emails_sublabel': 'получатели',
                'topic_builder_alerts_interval_sublabel': 'времеви интервал (в минути)',
                'topic_builder_alerts_interval_hourly': 'всеки час',
                'topic_builder_alerts_interval_daily': 'всеки ден',
                'topic_builder_alerts_interval_weekly': 'всяка седмица',
                'topic_builder_alerts_interval_n_mins': 'всеки {{ interval }} мин',
                'topic_builder_alerts_interval_or_custom': 'или друго:',
                'topic_builder_alerts_alert': 'известие',

                'topic_builder_qna_expand_all': 'разшири всички',
                'topic_builder_qna_collapse_all': 'скрий всички',
                'topic_builder_qna_new': 'добави нов въпрос',
                'topic_builder_qna_answer_new': 'добави отговор',
                'topic_builder_qna_question': 'Въпрос',
                'topic_builder_qna_question_delete': 'изтрий въпрос',
                'topic_builder_qna_question_duplicate': 'създай копие',
                'topic_builder_qna_question_duplicate_below': 'постави копие отдолу',
                'topic_builder_qna_question_duplicate_at_end': 'постави копие накрая',
                'topic_builder_qna_can_choose_multiple': 'потребителите могат да избират повече от един отговор',
                'topic_builder_qna_show_for_range': 'покажи ако оценка е в диапазона',
                'topic_builder_qna_vote_range_inclusive': '* избраните диапазони са включителни',
                'topic_builder_qna_jump_only': 'само чрез скок',
                'topic_builder_qna_jump_only_info': '* ще се покаже само при скок от друг въпрос',
                'topic_builder_qna_open_ended': 'отворен въпрос',
                'topic_builder_qna_multiple_choice': 'фиксирани отговори',
                'topic_builder_qna_dropdown': 'падащо меню',
                'topic_builder_qna_data_warning': 'Ще загубите текущите данни. Сигурни ли сте, че искате да продължите?',
                'topic_builder_qna_answer_is_link': 'Отговорът е линк',
                'topic_builder_qna_jump_to_question': 'скочи към въпрос',
                'topic_builder_qna_openended_label': 'текстов шаблон',
                'topic_builder_qna_openended_sublabel': 'опционален шаблон в текстовото поле',
                'topic_builder_qna_openended_allow_upload': 'Позволи качване на изображение',
                'topic_builder_qna_dropdown_answer_label': 'Възможни отговори',
                'topic_builder_qna_dropdown_answer_sublabel': 'Напишете стойностите, които да излизат в падащото меню, разделени с точка и запетая (;)',

                'topic_builder_after_vote_show': 'покажи',
                'topic_builder_after_vote_most_people': 'повечето хора са/не са съгласни с Вас',
                'topic_builder_after_vote_most_men_women': 'повечето мъже/жени са/не са съгласни с Вас',
                'topic_builder_after_vote_most_people_your_age': 'повечето хора на Вашата възраст са/не са съгласни с Вас',
                'topic_builder_after_vote_most_men_women_your_age': 'повечето мъже/жени на Вашата възраст са/не са съгласни с Вас',
                'topic_builder_after_vote_most_people_in_your_city': 'повечето хора във Вашия град са/не са съгласни с Вас',
                'topic_builder_after_vote_vote_again': 'гласувай отново на тази тема',
                'topic_builder_after_vote_copy_voting_link': 'копирай линк към темата',
                'topic_builder_after_vote_share_on_facebook': 'сподели във Фейсбук',
                'topic_builder_after_vote_copy_voting_link_sublabel': 'копирането на линк към темата не е позволено за скрити теми',
                'topic_builder_after_vote_share_on_facebook_sublabel': 'споделянето във Фейсбук не е позволено за скрити теми',
                
                'topic_builder_greetings_add_new': 'добави съобщение',
                'topic_builder_greetings_time_to_show': 'време за показване на начално съобщение',
                'topic_builder_greetings_seconds': 'секунди',

                'topic_builder_languages_default_label': 'език по подразбиране',
                'topic_builder_languages_add_alternative': 'добави алтернативен език',
                'topic_builder_languages_enfoce_language_label': 'наложи език',
                'topic_builder_languages_enfoce_language_sublabel': 'наложеният език ще се показва за тази тема, независимо от предпочитанията на потребителя',
                'topic_builder_languages_details_title': 'Превод на основни данни',
                'topic_builder_languages_details_title_label': 'Заглавие',
                'topic_builder_languages_details_description_label': 'Описание',
                'topic_builder_languages_qna_title': 'Превод на въпроси и отговори',
                'topic_builder_languages_qna_answers_title': 'Отговори',
                'topic_builder_languages_greetings_title': 'Превод на начални съобщения',

                'topic_builder_link_config_description': 'Линковете за споделяне в социалните мрежи ще бъдат стилизирани, както е показано по-долу.',
                'topic_builder_link_config_description_1': 'Вие можете да ',
                'topic_builder_link_config_description_2': 'промените',
                'topic_builder_link_config_description_3': ' описанието и изображението, което ще се показва в социалните мрежи',
                'topic_builder_link_config_reset_to_default': 'Върни към оригинално',
                'topic_builder_link_config_customize': 'Редактирай',
                'topic_builder_link_config_sources_title': 'Източници на линкове към темата',
                'topic_builder_link_config_sources_subtitle': 'Добави линкове за гласуване с източници за следене на оценките. Всеки линк към темата може да включва до 3 имена на източници. Всяко име на източник може да съдържа до 20 символа (букви и цифри).',
                'topic_builder_link_config_add_link_sources': 'добави линк с източници',
                'topic_builder_link_config_link_preview': 'преглед на линк към тема',

                'topic_builder_link_config_upload_custom': 'Качи изображение и текст на описание',
                'topic_builder_link_config_link_image_label': 'изображение на линка',
                'topic_builder_link_config_link_image_sublabel': 'изображение, което се използва в линка за споделяне с максимална резолюция 1200 на 630. Може да бъде .gif с максимален размер 300kb',
                'topic_builder_link_config_link_description_label': 'текст на описание на линка',
                'topic_builder_link_config_link_description_sublabel': 'описателен текст след Вашия линк с максимална дължина 300 символа. Използвайте за описание на желания от Вас език',
                'topic_builder_link_config_link_preview_link': 'Прегледай',
            }
        }
    },
    detection: {
        order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        caches: ['localStorage', 'cookie'],
    },
    fallbackLng: "bg",
    interpolation: {
        escapeValue: false
    }
});

export default i18n;