import { isEmpty } from 'lodash';

export function getTopicModel(defaultLanguageCode = "en") {
    return {
        basicSettings: {
            title: "",
            urlPreview: "",
            displayName: "",
            dashName: "",
            description: "",
            defaultLang: defaultLanguageCode,
            extDescriptionUrl: "",
            image: {},
            processedImage: null,
            imageUrl: "",
            backgroundUrl: "",
            backgroundThumbnailUrl: "",
            backgroundPortraitUrl: "",
            entityId: null,
            voterCanSuggestPic: false,
            voterCanAddComment: false,
            voterShouldConcent: true,
            isPrivate: false,
            isDisabled: false,
            isRoot: false
        },
        config: {
            parent: {
                parentTopicId: '',
                parentTopicName: ''
            },
            enforceLang: '',
            timeZone: '',
            areaCoords: [],
            topicsChain: [],
            channels: [],
            alerts: [],
            alertsActive: false
        },
        linkConfig: {
            linkDescription: "",
            linkImage: "",
            sources: []
        },
        qna: [],
        postVoteSettings: {
            showPeopleStats: true,
            showGenderStats: true,
            showAgeStats: true,
            showGenderByAgeStats: true,
            showLocationStats: true,
            showVoteAgain: true,
            showCopyLink: true,
            showFbShare: true,
            reward: null
        },
        greetings: {
            messages: [],
            duration: 2
        },
        translations: {},
        level: null,
        version: 1
    };
}

export function combineTopicModelsForEdit(streamModel, graphModel) {
    const topicModel = Object.assign({}, streamModel, {
        qna: streamModel.qna.map(qnaItem => _toQnaEditModel(qnaItem))
    });
    topicModel.config.alertsActive = graphModel.alertsActive || false;
    topicModel.config.alerts = !isEmpty(graphModel.alerts) ? [...graphModel.alerts] : [];
    topicModel.createdOn = graphModel.createdOn;
    topicModel.createdBy = graphModel.createdBy;
    topicModel.modifiedOn = graphModel.modifiedOn;
    topicModel.modifiedBy = graphModel.modifiedBy;
    return topicModel;
}

export function mapToTopicEditRequestModel(topicModel) {
    const topicRequest = Object.assign({}, topicModel);
    topicRequest.qna = topicModel.qna.map(qnaItem => _toQnaRequestModel(qnaItem));
    return topicRequest;
}

const _toQnaRequestModel = qnaItem => {
    return Object.assign({}, qnaItem, {
        answers: (qnaItem.answers || []).map(answer => {
            const { answerId, text, link, canAttachImage, placeholderText, nextQuestionId } = answer;
            return { answerId, text, link, canAttachImage, placeholderText, nextQuestionId };
        })
    });
};

const _toQnaEditModel = qnaItem => {
    return Object.assign({}, qnaItem, {
        answers: (qnaItem.answers || []).map(answer =>
            Object.assign({}, answer, {
                jumpToQuestionEnabled: !!answer.nextQuestionId,
                canAttachImage: answer.canAttachImage || false
            })
        )
    });
};