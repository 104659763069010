import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TopicsList from './topicsList';
import { Link } from 'react-router';
import { BarChart2, Link as LinkIcon, Edit } from 'react-feather';

import VotesTrendChart from '../topics/votesTrendChart';
import { uniq, isOnMobile } from '../../helpers/utils';
import { getTopicLinksModalConfig } from '../../helpers/modalDialogsHelper';
import { TOPIC_LINKS_MODAL } from '../../helpers/modalTypes';
import PermissionsContext from '../../scenes/contexts/permissionsContext';
import { PERMISSIONS } from '../../helpers/permissionsHelper';
import FeaturesContext from "../../scenes/contexts/featuresContext";
import { COMPARATORS } from '../../helpers/featuresHelper';
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';
import RatingBadge from '../common/ratingBadge';
import i18n from '../../i18n';

const VOTES_TO_DISPLAY = 10;

class TopicsListItem extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isCollapsed: false,
			childrenNestLevel: props.nestLevel ? props.nestLevel + 1 : 1,
			elementStyleIsSet: false,
			listItemHeight: null,
			topicLatestVotes: _getSortedLastVotes(props.topic.latestVotes) || [],
            t: i18n.t.bind(i18n),
		};

		this.toggleExpandCollapse = this.toggleExpandCollapse.bind(this);
        this.resizeHandler = this.resizeHandler.bind(this);
        this.openLinksModal = this.openLinksModal.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const uniqTopicLatestVotes = uniq((nextProps.topic.latestVotes || []).concat(prevState.topicLatestVotes));

		if (uniqTopicLatestVotes.length !== prevState.topicLatestVotes.length) {
			const topicLatestVotes = _getSortedLastVotes(nextProps.topic.latestVotes);
			return({ topicLatestVotes: topicLatestVotes, elementStyleIsSet: false });
		}

		return null;
	}

	componentDidMount(){
		const listItemHeight = this.getListItemHeightValue();
		this.setStackedListItemStyles(listItemHeight);
		
		window.addEventListener("resize", this.resizeHandler);
	}

	componentDidUpdate(prevProps, prevState){
		const listItemHeight = this.getListItemHeightValue();
		this.setStackedListItemStyles(listItemHeight);

		if (!prevState.elementStyleIsSet) {
			this.setState({ elementStyleIsSet: true, listItemHeight });
		}
	}

	componentWillUnmount(){
		window.removeEventListener("resize", this.resizeHandler);
	}
	
	openLinksModal() {
		const topicName = this.props.topic.dashName ? this.props.topic.dashName : this.props.topic.name;
		const topicLinksConfig = getTopicLinksModalConfig(this.props.topic.id, topicName);
		const modalComponentProps = Object.assign({}, topicLinksConfig, {
			onClose: this.context.hideModal
		});
		this.context.showModal(TOPIC_LINKS_MODAL, modalComponentProps);
	}

	resizeHandler(){
		this.setState({ elementStyleIsSet: false });
	}

	getListItemHeightValue() {
		const lisItemDom = this.treeItemDom.getElementsByClassName('list-group-item');

		if (lisItemDom.length > 0) {
			return lisItemDom[0].clientHeight;
		}

		return null;
	}

	setStackedListItemStyles(height){
		if (this.treeItemDom && this.props.isStacked & height !== null) {
			this.stackedOverlay = this.treeItemDom.getElementsByClassName('stackedOverlay')[0];

			if (this.stackedOverlay) {
				this.stackedOverlay.style.height = `${height}px`;
				this.stackedOverlay.style.marginBottom = `-${height}px`;
			}
		}
	}

	toggleExpandCollapse(){
		this.setState(prevState => ({
			isCollapsed: !prevState.isCollapsed
		}));
	}

	render() {
		const { topic, index, isStacked } = this.props;
		
		let childTopicsList = [];
		if (topic.children && topic.children.length > 0) {
			childTopicsList = topic.children;
		}

		let listGroupItemClassName = `list-group-item clearfix level-${this.props.nestLevel}`;
		let topicTreeItemClassName = 'topic-tree-item clearfix';

		if (isStacked) {
			listGroupItemClassName += ' stacked';
			topicTreeItemClassName += ' stacked';
		}

		const dropDownDirection = this.state.isCollapsed ? 'right' : 'up';
		const expandCollapseClassName = `expand-collapse glyphicon glyphicon-menu-${dropDownDirection} pull-right`;
 
		const elementStyle = isStacked ? { zIndex: index ? -index : 0 } : {};
		
		return (
			<div className={topicTreeItemClassName} style={elementStyle} ref={(treeItemDom) => this.treeItemDom = treeItemDom}>
				{isStacked && 
					<div className="stackedOverlay">&nbsp;</div>
				}
				<div className={listGroupItemClassName}>
					<div className="pull-left clearfix">
						{childTopicsList.length > 0 &&	
							<span className={expandCollapseClassName} onClick={this.toggleExpandCollapse}></span>
						}
					</div>
					<Link to={`/topics/details/${topic.id}/graphs`} className='topic-tree-item-link clearfix'>
						<span>{topic.dashName ? topic.dashName : topic.name} <small><i className="glyphicon glyphicon-new-window"></i></small></span>
					</Link>
					{topic.latestVotes && topic.latestVotes.length > 0 && 
						<VotesTrendChart votesDesc={this.state.topicLatestVotes} maxCount={VOTES_TO_DISPLAY} smallLabel={true}/>
					}
					<div>
						<Link to={`/topics/details/${topic.id}/graphs`} className='btn btn-link topic-tree-item-button'>
							<BarChart2 size={20} />
						</Link>
						{!isOnMobile() && <button type='button' onClick={this.openLinksModal} className='btn btn-link topic-tree-item-button'>
							<LinkIcon size={20} />
						</button>}
						<PermissionsContext hideIfNoPermission={true} requiredPermissions={[PERMISSIONS.EDIT_TOPICS]}>
							<FeaturesContext 
								requiredFeatures={[{ name: ENTITY_FEATURES.TOPIC_EDIT, compare: COMPARATORS.IS_TRUE }]}
								renderOtherwise={<button className='btn btn-link topic-tree-item-button' onClick={() => {}}>
									<Edit size={20}/>
								</button>}
							>
								<Link to={`/topics/edit/${topic.id}`} className='btn btn-link topic-tree-item-button'>
									<Edit size={20}/>
								</Link>
							</FeaturesContext>
						</PermissionsContext>
					</div>
					<div className="rating-bar">
						<div className="text-right votes-volume-container hide-on-mobile">
							<span><strong>{topic.voteCount}</strong> {this.state.t('topics_tree_direct_votes')}</span>
							<br />
							{topic.avgRelatedVote > 0 &&
							<span>
								<strong>{topic.aggregateVoteCount}</strong> {this.state.t('topics_tree_related_votes')}
							</span>
							}
						</div>

						<div className="text-left rating">
							<RatingBadge vote={topic.avgVote || 0} size={16} />
							
							{topic.avgRelatedVote > 0 &&
							<span>
							<br />
							<RatingBadge className="rating-relative-badge" vote={topic.avgRelatedVote || 0} size={13} />
							</span>
							}
						</div>
					</div>
				</div>
				{childTopicsList.length > 0 && !isStacked &&
					<TopicsList 
						topics={childTopicsList}
						hidden={topic.match ? false : this.state.isCollapsed} 
						nestLevel={this.state.childrenNestLevel} 
					/>
				}
			</div>
		);
	}
}

TopicsListItem.contextTypes = {
	showModal: PropTypes.func,
	hideModal: PropTypes.func
};

TopicsListItem.propTypes = {
	topic: PropTypes.object.isRequired,
	index: PropTypes.number,
	nestLevel: PropTypes.number.isRequired,
	isStacked: PropTypes.bool.isRequired,
};

const _getSortedLastVotes = (votes) => {
	const topicVotes = votes || [];
	let latestVotesDescending = topicVotes.sort((a,b) => {return b.rowId - a.rowId;});
	
	return latestVotesDescending;
};

export default TopicsListItem;