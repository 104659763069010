import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import * as Icons from 'react-feather';
import { isEmpty } from 'lodash';
import BSFormControl from '../common/form/bsFormControl';
import { Toggle } from '../common/form/toggle';
import Autocomplete from '../common/form/autocomplete';
import ServerErrorMessage from '../common/serverErrorMessage';

import CountriesList from '../../helpers/countries';
import Languages from '../../helpers/languages';
import { isOnMobile } from '../../helpers/utils';
import { PERMISSIONS } from '../../helpers/permissionsHelper';

import './css/entityDetailsForm.css';
import { ENTITY_TIER_IDS, ENTITY_TIERS_LIST } from '../../actions/models/entityModel';
import PermissionsContext from '../../scenes/contexts/permissionsContext';
import MediaDnD from '../common/form/MediaDnD';
import { processLogoImage } from '../../helpers/imageUtils';
import DebouncedInputField from '../common/form/debouncedInputField';

class EntityDetailsForm extends Component {
    constructor(props, context){
        super(props, context);
        this.state = {
            isCancelModalShown: false,
            isValid: true,
            isFreeTier: this.props.editMode && this.props.values.tierId === ENTITY_TIER_IDS.FREE,
            rootTopicExists: this.props.editMode && !isEmpty(this.props.values.rootTopicName)
        };
        this.validateTopicName = this.validateTopicName.bind(this);
    }
    
    handleSetValue(value, field){
        this.props.setFieldValue(`${field}`, value);
    }
    
    handleTierIdSet(value) {
        // html select option transforms its value to string, thus parsing is needed here
        const tierId = parseInt(value);
        const { setFieldValue, setFieldTouched, entityActiveTierId } = this.props;
        const newTierState = {};

        if (entityActiveTierId !== ENTITY_TIER_IDS.FREE) {
            newTierState.isChangedTier = entityActiveTierId !== tierId;
            newTierState.isFreeTier = tierId === ENTITY_TIER_IDS.FREE;
        }

        this.setState(newTierState);

        setFieldValue('tierId', tierId);
        setFieldTouched('tierId');
    }

    fieldHasErrors(errors, field) {
        if (this.props.requestErrors && this.props.requestErrors[field] && this.props.requestErrors[field].length > 0) {
            return true;
        }
        return errors[field] && errors[field].length > 0;
    }

    getLanguagesOptions() {
        return Languages.map(l => {
            return (
                <option key={l.code} value={l.code}>
                    {l.name}
                </option>
            );
        });
    }

    getTierOptions() {
        return ENTITY_TIERS_LIST.map(tier => {
            return (
                <option key={tier.id} value={tier.id} disabled={false}>
                    {tier.name}
                </option>
            );
        });
    }

    validateTopicName(topicName) {
        this.props.validateTopicName(topicName);
    }
    
    render(){
        const { requestErrors, errors, values, setFieldValue, setFieldError, handleBlur } = this.props;
        return (
            <>
                <PermissionsContext hideIfNoPermission={true} requiredPermissions={ [PERMISSIONS.ADMIN] }>
                    <div className="row-fluid clearfix">
                        <div className="col-md-4 col-xs-12">
                            <div className="form-group clearfix">
                                <BSFormControl label="account tier" className='zero-margin' hasErrors={this.fieldHasErrors(errors, 'tierId')}>
                                    <Field name='tierId'>
                                        {({ field }) => (
                                            <select
                                                className='form-control'
                                                value={field.value}
                                                onChange={event => {
                                                    this.handleTierIdSet(event.target.value);
                                                }}
                                            >
                                                { this.getTierOptions() }
                                            </select>
                                        )}
                                    </Field>
                                    {(this.state.isChangedTier && this.state.isFreeTier) && (
                                        <p className="message bg-warning uitest-warning">
                                            <Icons.AlertTriangle size={16} /> WARNING: <strong>Downgrading</strong> paid accounts that have an active subscription will result in <strong>error</strong>
                                        </p>
                                    )}
                                    {(this.state.isChangedTier && !this.state.isFreeTier) && (
                                        <p className="message bg-warning uitest-warning">
                                            <Icons.AlertTriangle size={16} /> WARNING: You are changing the tier of an already paid account. Make sure you <strong>know</strong> what you are doing.
                                        </p>
                                    )}
                                    <ErrorMessage name='tierId' component='div' className='control-label' />
                                </BSFormControl>
                            </div>
                        </div>

                        <div className="col-md-8 col-xs-12">
                            <div className="form-group clearfix">
                                <BSFormControl label="" className='zero-margin'>
                                    <Field name="isActive">
                                        {({ field }) =>
                                            <Toggle value={field.value} onChange={(value) => this.handleSetValue(value, field.name)} />
                                        }
                                    </Field>
                                    <label className="control-label" htmlFor="timezone">active</label>
                                    <label className="sub-label">If organization is inactive, all associated topics will be hidden for voting</label>
                                </BSFormControl>
                            </div>
                        </div>
                    </div>
                </PermissionsContext>

                <div className={`row-fluid clearfix entity-logo-container ${isOnMobile() ? '' : 'fit-desktop-flex-container'}`}>
                    <div className={`pull-left col-xs-12 col-md-4 entity-logo-upload ${isOnMobile() ? '' : 'fit-desktop-flex-item'}`}>
                        {!values.id ?
                            <p style={{ margin: '10px 0 30px 10px' }}>
                                След създаването на организацията, можете да прикачите лого.
                            </p> :
                            <MediaDnD
                                label={'organization logo'}
                                mediaUrl={values.logoUrl}
                                uploadImageEndpoint={`/entities/${values.id}/upload_images/logo`}
                                imageProcessor={processLogoImage}
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                                fieldNames={['logoUrl']}
                                showPreview={false}
                            />
                        }
                    </div>
                    
                    <div className={`pull-left col-xs-12 col-md-8 entity-main-data ${isOnMobile() ? '' : 'fit-desktop-flex-item'}`}>
                        <div className="row-fluid clearfix">
                            <div className="col-md-6 col-xs-12">
                                <BSFormControl label='organization name' required hasErrors={this.fieldHasErrors(errors, 'name')}>
                                    <Field type="text" name="name" className='form-control' placeholder="My New Organization Name" autoComplete="noautocompletepls" disabled={!this.props.isUserAdmin} />
                                    <ErrorMessage name="name" component="span" className='control-label' />
                                    <ServerErrorMessage errors={requestErrors} name="name" className="control-label" />
                                </BSFormControl>
                            </div>

                            <div className="col-md-6 col-xs-12">
                                <BSFormControl label='organization url' hasErrors={this.fieldHasErrors(errors, 'url')}>
                                    <Field type="text" name="url" className="form-control" placeholder="e.g. www.example.com" autoComplete="noautocompletepls" />
                                    <ErrorMessage name="url" component="span" className='control-label' />
                                </BSFormControl>
                            </div>
                        </div>

                        <div className="row-fluid clearfix">
                            <div className="col-md-6 col-xs-12">
                                <BSFormControl label='root topic name' required hasErrors={this.fieldHasErrors(errors, 'rootTopicName')}>
                                {this.state.rootTopicExists ? (
                                    <Field type="text" name="rootTopicName" className="form-control" disabled autoComplete="noautocompletepls" />
                                ) : (
                                    <Field name="rootTopicName">
                                        {({ field }) => (
                                            <DebouncedInputField 
                                                onDebouncedAction={this.validateTopicName}
                                                value={field.value}
                                                debounceOnChangeTimeout={1500}
                                                placeholder="My New Organization's Root Topic Name" 
                                                onValueChange={(topicName) => this.handleSetValue(topicName, field.name)}
                                                onBlur={handleBlur}
                                                onFocus={handleBlur}
                                                name={field.name}
                                            />
                                        )}
                                    </Field>
                                )}
                                    <ErrorMessage name="rootTopicName" component="span" className='control-label' />
                                    <ServerErrorMessage errors={requestErrors} name="rootTopicName" className="control-label" />
                                </BSFormControl>
                            </div>

                            <div className="col-md-6 col-xs-12">
                                <BSFormControl label="default topic language">
                                    <Field component="select" className="form-control" name="defaultTopicLanguage">
                                        { this.getLanguagesOptions() }
                                    </Field>
                                    <ErrorMessage name="defaultTopicLanguage" component="span" className='control-label'/>
                                    <ServerErrorMessage errors={requestErrors} name="defaultTopicLanguage" className="control-label"/>
                                </BSFormControl>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row-fluid clearfix">
                    <BSFormControl label='organization address' hasErrors={this.fieldHasErrors(errors, 'address')}>
                        <Field type="text" name="address" className="form-control" autoComplete="noautocompletepls" />
                        <ErrorMessage name="address" component="span" className='control-label' />
                    </BSFormControl>
                </div>
                <div className="row-fluid clearfix">
                    <div className="col-md-6 col-xs-12">
                        <BSFormControl label='country' hasErrors={this.fieldHasErrors(errors, 'countryCode')}>
                            <Field name="countryCode">
                                {({ field }) =>
                                    <Autocomplete
                                        className="form-control"
                                        placeholder='Select Country'
                                        data={CountriesList}
                                        valueField='code'
                                        value={field.value}
                                        onChange={(value) => { this.handleSetValue(value, field.name); }}
                                    />
                                }
                            </Field>
                            <ErrorMessage name="countryCode" component="span" className='control-label' />
                        </BSFormControl>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <BSFormControl label='city' hasErrors={this.fieldHasErrors(errors, 'city')}>
                            <Field type="text" name="city" className="form-control" autoComplete="noautocompletepls" />
                            <ErrorMessage name="city" component="span" className='control-label' />
                        </BSFormControl>
                    </div>
                </div>
                <div className="row-fluid clearfix">
                    <div className="col-md-6 col-xs-12">
                        <BSFormControl label='state' hasErrors={this.fieldHasErrors(errors, 'state')}>
                            <Field type="text" name="state" className="form-control" autoComplete="noautocompletepls" />
                            <ErrorMessage name="state" component="span" className='control-label' />
                        </BSFormControl>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <BSFormControl label='postal code' hasErrors={this.fieldHasErrors(errors, 'postalCode')}>
                            <Field type="text" name="postalCode" className="form-control" autoComplete="noautocompletepls" />
                            <ErrorMessage name="postalCode" component="span" className='control-label' />
                        </BSFormControl>
                    </div>
                </div>
            </>
        );
    }

}

EntityDetailsForm.defaultProps = {
    validateTopicName: () => {}
};

EntityDetailsForm.propTypes = {
	values: PropTypes.shape({
		id: PropTypes.number,
		isActive: PropTypes.bool,
		name: PropTypes.string,
		rootTopicName: PropTypes.string,
		tierId: PropTypes.number,
    }).isRequired,
    entityActiveTierId: PropTypes.number,
    errors: PropTypes.object,
	editMode: PropTypes.bool.isRequired,
	requestErrors: PropTypes.object,
    usersCount: PropTypes.number,
    isUserAdmin: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    handleBlur: PropTypes.func,
    validateTopicName: PropTypes.func.isRequired
};

EntityDetailsForm.contextTypes = {
    hideModal: PropTypes.func,
    showModal: PropTypes.func,
    router: PropTypes.object
};

export default EntityDetailsForm;

