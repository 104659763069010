import formValidators from "./formValidators";
import Messages from "./messages";
import { isEmpty, xor } from 'lodash';
import sharedValidators from 'hollerlive-shared/validators';

export const TIMEZONES = [
	{ id: 1, code: "GMT-12:00", name: "(GMT -12:00) Eniwetok, Kwajalein" },
	{ id: 2, code: "GMT-11:00", name: "(GMT -11:00) Midway Island, Samoa" },
	{ id: 3, code: "GMT-10:00", name: "(GMT -10:00) Hawaii" },
	{ id: 4, code: "GMT-9:00", name: "(GMT -9:00) Alaska" },
	{ id: 5, code: "GMT-8:00", name: "(GMT -8:00) Pacific Time (US &amp; Canada)" },
	{ id: 6, code: "GMT-7:00", name: "(GMT -7:00) Mountain Time (US &amp; Canada)" },
	{ id: 7, code: "GMT-6:00", name: "(GMT -6:00) Central Time (US &amp; Canada), Mexico City" },
	{ id: 8, code: "GMT-5:00", name: "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima" },
	{ id: 9, code: "GMT-4:00", name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz" },
	{ id: 11, code: "GMT-3:30", name: "(GMT -3:30) Newfoundland" },
	{ id: 12, code: "GMT-3:00", name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown" },
	{ id: 13, code: "GMT-2:00", name: "(GMT -2:00) Mid-Atlantic" },
	{ id: 14, code: "GMT-1:00", name: "(GMT -1:00 hour) Azores, Cape Verde Islands" },
	{ id: 15, code: "GMT", name: "(GMT) Western Europe Time, London, Lisbon, Casablanca" },
	{ id: 16, code: "GMT+1:00", name: "(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris" },
	{ id: 17, code: "GMT+2:00", name: "(GMT +2:00) Kaliningrad, South Africa" },
	{ id: 18, code: "GMT+3:00", name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg" },
	{ id: 19, code: "GMT+3:30", name: "(GMT +3:30) Tehran" },
	{ id: 21, code: "GMT+4:00", name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi" },
	{ id: 22, code: "GMT+4:30", name: "(GMT +4:30) Kabul" },
	{ id: 23, code: "GMT+5:00", name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent" },
	{ id: 24, code: "GMT+5:30", name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi" },
	{ id: 25, code: "GMT+5:45", name: "(GMT +5:45) Kathmandu" },
	{ id: 26, code: "GMT+6:00", name: "(GMT +6:00) Almaty, Dhaka, Colombo" },
	{ id: 27, code: "GMT+7:00", name: "(GMT +7:00) Bangkok, Hanoi, Jakarta" },
	{ id: 28, code: "GMT+8:00", name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong" },
	{ id: 29, code: "GMT+9:00", name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk" },
	{ id: 30, code: "GMT+9:30", name: "(GMT +9:30) Adelaide, Darwin" },
	{ id: 31, code: "GMT+10:00", name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok" },
	{ id: 32, code: "GMT+11:00", name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia" },
	{ id: 33, code: "GMT+12:00", name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka" }
];

export const ANSWER_TYPES = ['singleselect', 'multiselect'];

export const ANSWERS_STRUCTURE = ['open', 'close', 'dropdown'];

export const STEPS_NAMES = {
	basic: 'basicSettings',
	config: 'config',
	qna: 'qna',
	afterVote: 'afterVote',
	greetings: 'greetings',
	translations: 'translations',
	linkConfig: 'linkConfig'
};

export const stepStates = {
    NOT_STARTED: "not-started",
    DONE: "done",
    HAS_ERRORS: "has-errors",
    HAS_WARNING: "has-warning"
};

export const mcChannels = [
	{id: 0, name: 'now'}
];

export const EMPTY_QUESTION_TEMPLATE = {
	questionId: null,
	question: "",
	showForRange: [],
	answerSctructure: ANSWERS_STRUCTURE[0],
	answerType: ANSWER_TYPES[0]
};

export const EMPTY_ANSWER_TEMPLATE = {
	answerId: null,
	text: "",
	link: "",
	isLink: false,
	placeholderText: "",
	nextQuestionId: null,
	jumpToQuestionEnabled: false,
	canAttachImage: false,
};

export const DEFAULT_ALERT_TEMPLATE = {
    range: [0, 10],
    interval: 30,
    emails: []
};

export const LINK_IMAGE_DIMENSIONS = { WIDTH: 1200, HEIGHT: 630 };
// max size of gifs in bytes:
export const LINK_IMAGE_GIF_MAX_SIZE = 300000;
export const LINK_SOURCES_MAX_COUNT = 3;

export function getSliderMarks() {
	const marks = {};

	for (let index = 1; index <= 10; index++) {
		marks[index] = index.toString();
	}

	return marks;
}

export function validateBasicTopicDetails(basicSettingsValues, configValues) {
	const formErrors = {};

	if (basicSettingsValues.isPrivate && !isEmpty(configValues.channels)) {
		formErrors.isPrivate = Messages.topics.topicInChannelRefusePrivate;
	} else if (!formValidators.required(basicSettingsValues.title)) {
		formErrors.title = Messages.errors.required;
	} else if (!formValidators.length(basicSettingsValues.title, null, 30)) {
		formErrors.title = Messages.errors.length(null, 30);
	} else if (!formValidators.length(basicSettingsValues.displayName, null, 160)) {
		formErrors.displayName = Messages.errors.length(null, 160);
	} else if (!formValidators.onlyText(basicSettingsValues.title)) {
		formErrors.title = Messages.errors.onlyText;
	}

	return formErrors;
}

export function validateTopicConfiguration(configValues, forceValidate = false) {
	const formErrors = {};
	
	const alertsErrors = [];
	
	configValues.alerts.forEach((alert, index) => {
		const alertError = {};
		// validate current alert range
		if (alert.range && alert.range.length === 2) {
			if (alert.range[0] < 0 || alert.range[0] > 10) {
				alertError.range = `Range ${Messages.errors.length(0, 10).substring(7)}`;
			}
		} else {
			alertError.range = Messages.errors.required;
		}
		
		// validate current alert emails list
		if (alert.emails && alert.emails.length > 0) {
			alert.emails.forEach(email => {
				if (!sharedValidators.isEmailValid(email.trim())) {
					alertError.emails = Messages.errors.email;
				}
			});
		} else {
			alertError.emails = Messages.errors.required;
		}
		
		// validate current alert interval
		if (alert.interval) {
			if (!formValidators.onlyNumeric(alert.interval)) {
				alertError.interval = Messages.errors.onlyNumeric;
			} else if (alert.interval < 5) {
				alertError.interval = Messages.errors.length(5);
			}
		} else {
			alertError.interval = Messages.errors.required;
		}
		
		alertsErrors.push(alertError);
	});
	
	// validate matching range on different alerts; return error unless both emails and interval are different
	configValues.alerts.forEach((alert, alertIndex) => {
		configValues.alerts.forEach((alertComparator, alertComparatorIndex) => {
			if (alertIndex !== alertComparatorIndex && xor(alert.range, alertComparator.range).length === 0) {
				
				// previously assigned errors are with higher precedence
				if (!alertsErrors[alertIndex].emails &&
					!alertsErrors[alertComparatorIndex].emails &&
					!alertsErrors[alertIndex].interval &&
					!alertsErrors[alertComparatorIndex].interval &&
					(isEmpty(xor(alert.emails, alertComparator.emails)) || alert.interval === alertComparator.interval)
				) {
					alertsErrors[alertIndex] = Object.assign({}, alertsErrors[alertIndex], {
						range: 'Not allowed: Vote range must not be the same when email list or interval is matching.',
					});
					alertsErrors[alertComparatorIndex] = Object.assign({}, alertsErrors[alertComparatorIndex], {
						range: 'Not allowed: Vote range must not be the same when email list or interval is matching.',
					});
				}
				
			}
		});
	});
	
	if (alertsErrors.some(obj => !isEmpty(obj))) {
		formErrors.alerts = alertsErrors;
	}
	return formErrors;
}

export function validateLinkConfig(linkConfigValues) {
	const linkConfigErrors = {};

	if (!formValidators.length(linkConfigValues.linkDescription, null, 300)) {
		linkConfigErrors.linkDescription = Messages.errors.length(null, 300);
	}

	const sourcesErrors = [];
	linkConfigValues.sources.forEach(linkSourceCsv => {
		const lsErrors = {};
		const sourcesPerLink = linkSourceCsv.value.split(',');

		sourcesPerLink.forEach(sourceName => {
			if (!sharedValidators.required(sourceName)) {
				lsErrors.value = `${Messages.errors.required}`;
			} else if (!sharedValidators.onlyLatinAphaNumericNoSpaces(sourceName)) {
				lsErrors.value = `Each source name ${Messages.errors.onlyLatinAphaNumericNoSpaces.toLowerCase()}`;			
			} else if (!sharedValidators.length(sourceName, 1, 21)) {
				lsErrors.value = `Each source name ${Messages.errors.length(1, 21).toLowerCase()}`;
			}
		});
		
		sourcesErrors.push(lsErrors);
	});

	if (sourcesErrors.some(obj => !isEmpty(obj))) {
		linkConfigErrors.sources = [...sourcesErrors];
	}

	return linkConfigErrors;
}


export function validateQna(qnaValues = []) {
    const questionsErrors = [];
    qnaValues.forEach((qValue, index, qnaVals) => {
        const qErrors = {};
        const answersErrors = [];

        if (!formValidators.required(qValue.question)) {
            qErrors.question = Messages.errors.required;
        } else {
            const duplicate = qnaVals.find(
                (qnaVal, ind) =>
                    index !== ind && formValidators.isTrimmedStringEqual(qValue.question, qnaVal.question)
            );
            if (duplicate) {
                qErrors.question = Messages.topics.duplicateQuestionTitles;
            }
        }

        qValue.answers.forEach(aValue => {
            const aErrors = {};

            if (qValue.answerSctructure === ANSWERS_STRUCTURE[1] && !formValidators.required(aValue.text)) {
                aErrors.text = Messages.errors.required;
            }
	
						if (qValue.answerSctructure === ANSWERS_STRUCTURE[1] && aValue.isLink && (!formValidators.required(aValue.link) || !formValidators.url(aValue.link, true))) {
							aErrors.link = Messages.errors.url;
						}

            if (aValue.jumpToQuestionEnabled && !formValidators.required(aValue.nextQuestionId)) {
                aErrors.nextQuestionId = Messages.errors.required;
            }

            answersErrors.push(aErrors);
        });

        if (answersErrors.some(obj => !isEmpty(obj))) {
            qErrors.answers = answersErrors;
        }

        questionsErrors.push(qErrors);
    });

    if (questionsErrors.some(obj => !isEmpty(obj))) {
        return questionsErrors;
    } else {
        return [];
    }
}

export function validateGreetings(greetingsMessagesValues = []) {
	const greetingsErrors = {};
	const messagesErrors = [];

	greetingsMessagesValues.forEach( ( message ) => {
		const mErrors = {};

		if (!formValidators.required(message.text)) {
			mErrors.text = Messages.errors.required;
		} else if (!formValidators.length(message.text, null, 160)) {
			mErrors.text = Messages.errors.length(null, 160);
		}

		messagesErrors.push(mErrors);
	});

	if (messagesErrors.some(obj => !isEmpty(obj))) {
		greetingsErrors.messages = [...messagesErrors];
	} 
	return greetingsErrors;
}

export function validateTranslations(translationValues = {}, basicSettings = {}, qnaValues = {}) {
	const translationErrors = {};

	const langs = Object.keys(translationValues);
	
	langs.forEach(l => {
		if (!formValidators.required(translationValues[l].topicName)) {
			if (!translationErrors.hasOwnProperty(l)) {
				translationErrors[l] = {};
			}

			translationErrors[l].topicName = Messages.errors.required;
		}

		if (basicSettings && basicSettings.description && !formValidators.required(translationValues[l].description)) {
            if (!translationErrors.hasOwnProperty(l)) {
                translationErrors[l] = {};
            }
            
            translationErrors[l].description = Messages.errors.required;
		}

		if (!isEmpty(translationValues[l].qna)) {
			validateQnaTranslations(translationValues[l].qna, translationErrors, l, qnaValues);
		}

		if (!isEmpty(translationValues[l].greetings)) {
			validateGreetingsTranslations(translationValues[l].greetings, translationErrors, l);
		}
	});

	return translationErrors;
}

function validateQnaTranslations(values, errors, lang, qnaValues) {
	const qIds = Object.keys(values);

	qIds.forEach(id => {
		if (!formValidators.required(values[id].question)) {
			if (!errors.hasOwnProperty(lang)) {
				errors[lang] = {};
			}

			if (!errors[lang].hasOwnProperty('qna')) {
				errors[lang].qna = {};
			}

			errors[lang].qna[id] = { question: Messages.errors.required };
		}

		const question = qnaValues.find(q => Number(q.questionId) === Number(id));

		if (question && question.answerSctructure === 'close') {
			validateAnswersTextTranslations(values[id].answers, id, errors, lang);
		}
	});
}

function validateAnswersTextTranslations(values, qId, errors, lang) {
	if (!values) {
		return;
	}

	const aIds = Object.keys(values);

	aIds.forEach(aId => {
		if (!formValidators.required(values[aId].text)) {
			if (!errors.hasOwnProperty(lang)) {
				errors[lang] = {};
			}

			if (!errors[lang].hasOwnProperty('qna')) {
				errors[lang].qna = { [qId]: {} };
			}

			if (errors[lang].qna[qId]) {
				errors[lang].qna[qId].answers = Object.assign({}, errors[lang].qna[qId].answers, { [aId]: { text: Messages.errors.required }});
			} else {
				errors[lang].qna[qId] = { 
					answers: { 
						[aId]: { text: Messages.errors.required } 
					}
				};
			}
		}
	});
}

function validateGreetingsTranslations(values, errors, lang) {
	const gIds = Object.keys(values);
	gIds.forEach(id => {
		if (!formValidators.required(values[id].text)) {
			if (!errors.hasOwnProperty(lang)) {
				errors[lang] = {};
			}

			if (!errors[lang].hasOwnProperty('greetings')) {
				errors[lang].greetings = {};
			}

			errors[lang].greetings[id] = { text: Messages.errors.required };
		}
	});
}